import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteCustomerThunk } from "../../../store/customerThunks";
import FormAdd from "../forms/FormAdd";
import FormAddPet from "../forms/FormAddPet";
import { NavLink } from "react-router-dom";

const form_default={
  pk:null,
  name:'',
  identification_card:'',
  location:'',
  phone:'',
  profile:5
}

const TITLE="Clientes"
const TableCustomers = ({customers=[]}) => {
  
  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalCreatePet, setModalCreatePet] = useState(false);

  const [form, setForm] = useState(form_default);

  const user=useSelector((state)=>state.auth)  

  const dispatch = useDispatch()
  const selectItemEdit = (item)=>{
    setForm(item)
    setModalEdit(true)
  }

  const selectItemDelete = (item)=>{
    setForm(item)
    setModalDelete(true)
  }

  const selectAddPet=(item)=>{
    setForm(item)
    setModalCreatePet(true)
  }

  const deleteCustomer=async ()=>{
    dispatch(deleteCustomerThunk(form.pk))
    setModalDelete(false)
  }


  return (
    <>   
     <Table responsive="sm" bordered hover className="mt-2">
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Cedula</th>
            <th>Ubicacion</th>
            <th>Telefono</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
        { customers.map((item, index) => {
            return <tr key={index}>
              <td className="align-middle">{item.pk}</td>              
              <td className="align-middle" >
              <NavLink to={`/clientes/${item.pk}`} className="selectable">             
                {item.name}
                </NavLink>
                </td>
              
              <td>{item.identification_card}</td>
              <td>{item.location}</td>
              <td>
                <ul style={{listStyle:'none'}}>
                  {item.other_phone && item.other_phone.map(
                    (phones,i)=>
                    <li key={i} ><i className="fas fa-phone"></i> {phones} </li>)}
                </ul>
              </td>
              <td>
              <Button variant="danger" onClick={()=>selectAddPet(item)} className="m-2">
                <i className="fas fa-paw"></i>
              </Button>
              {user.role && user.role==='ADMIN' &&
              <>
              <Button variant="danger" onClick={()=>selectItemEdit(item)} className="m-2">
                <i className="fas fa-edit"></i>
              </Button>
            
              <Button variant="danger" onClick={()=>selectItemDelete(item)} className="m-2">
                <i className="fas fa-trash-alt"></i>
              </Button>   

                        
              </>
              }
              </td>
            </tr>
          })
        }
        </tbody>
      </Table>  
      <Modal
        show={modalDelete}
        onHide={() => setModalDelete(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
          {`Eliminar ${TITLE}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, Esta seguro que desea eliminar a <strong>{form.name}</strong>!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{setModalDelete(false)}}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={deleteCustomer}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>     
        
      <Modal
        show={modalEdit}
        onHide={() => setModalEdit(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {`Editar ${TITLE}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormAdd form_edit={form} closeModal={()=>setModalEdit(false)}/>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalCreatePet}
        onHide={() => setModalCreatePet(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {`Crear Mascota para ${TITLE}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormAddPet customer={{...form, 'property_user':form.profile.pk}} closeModal={()=>{setModalCreatePet(false)}} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TableCustomers