import React from 'react'
import { Image } from 'react-bootstrap'
import publicity from  '../../images/publicity.jpg'
// import logo_ubipets from  '../../images/logo_ubipets.png'
import './Banner.css'

const BannerPublicity = () => {
  return (<div className='banner-publicity'>
          <Image fluid style={{ width:'100%' }}
        src={publicity}
      />
    </div>)
}

export default BannerPublicity