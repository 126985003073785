import React from 'react'
import Loading from '../components/loading/Loading'

const LoadingScreen = () => {
  return (
    <div style={{ height:'100vh',width:'100%'}} className='d-flex justify-content-center align-items-center'>
        <Loading/>
    </div>
  )
}

export default LoadingScreen