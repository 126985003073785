import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { LayoutPublic } from "../../../components/layout/LayoutPublic";
import PaginationPet from "../../../components/pagination/PaginationPet";
import { loadPetsThunk } from "../../../store/petThunks";
import { resetMessages } from "../../../store/slices/petSlice";

import TablePets from "../components/table_pets";
import FormAdd from "../forms/FormAdd";
import FormFind from "../forms/FormFind";

const TITLE = "Mascotas";

const PetScreen = () => {
  const dispatch = useDispatch();
  const [modalCreate, setModalCreate] = useState(false);
  
  const { pets, messageError, messageSuccess, isLoading, total_items, current_page, total_pages } = useSelector(
    (state) => state.pet
  );

  
  useEffect(() => {
    dispatch(loadPetsThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (messageError) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: `Error: ${messageError}`,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        dispatch(resetMessages());
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageError]);

  useEffect(() => {
    if (messageSuccess) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `Success: ${messageSuccess}`,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        dispatch(resetMessages());
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageSuccess]);

  const activarPage = (prt_number_page) => {
    console.log(prt_number_page)
    dispatch(loadPetsThunk(prt_number_page, 10));
  };

  return (
    <LayoutPublic fluid>
      <Container>
        <h2>{TITLE}</h2>
        
        {/* <Button
          className="mb-3"
          variant="danger"
          onClick={() => setModalCreate(true)}
        >
          Agregar {TITLE}
          <i className="fas fa-plus-circle mx-2"></i>
        </Button> */}
        <Row>
          <Col>
            <FormFind />
          </Col>
        </Row>
        <div>
          {pets && (
            <>
              <TablePets pets={pets} />
              <PaginationPet
                activarPage={activarPage}
                total_items={total_items}
                current_page={current_page}
                total_pages={total_pages}
              />
            </>
          )}
          {isLoading && <h4>Cargando...</h4>}
        </div>
      </Container>

      <Modal
        show={modalCreate}
        onHide={() => {
          setModalCreate(false);
        }}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormAdd
            closeModal={() => {
              setModalCreate(false);
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </LayoutPublic>
  );
};

export default PetScreen;
