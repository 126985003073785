import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  veterinarians: [],
  currentPage:0,
  value:0,
  isEditing:false, 
  total_items:0,
  total_pages:1,
  current_page:1
}

export const veterinarianSlice = createSlice({
  name: 'veterinarian',
  initialState,
  reducers: {
    loadVeterinarians:(state,{payload})=>{
      state.veterinarians=payload.veterinarians
      state.currentPage=payload.currentPage
      state.total_items=payload.total_items
      state.total_pages=payload.total_pages
      state.current_page=payload.current_page
    },
    setVeterinarian:(state,{payload})=>{
      state.veterinarians.push(payload)
      state.isEditing=false
    },
    updateVeterinarian:(state,{payload})=>{
      state.veterinarians=state.veterinarians.map(item=>{
        if (item.pk===payload.pk){
          return payload
        }
        return item
      })
      state.isEditing=false
    },
    deleteVeterinarian:(state,{payload})=>{
      state.veterinarians=state.veterinarians.filter(item=>item.pk!==payload)
      state.isEditing=false
    },
    setEditing:(state)=>{
      state.isEditing=true
    }
  },
})

// Action creators are generated for each case reducer function
export const {setEditing, setVeterinarian, loadVeterinarians, updateVeterinarian, deleteVeterinarian} = veterinarianSlice.actions