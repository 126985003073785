import React from 'react'
import { Button } from 'react-bootstrap'

const EditButton = ({action=()=>{}, title=""}) => {
  return (    
    <Button variant="danger" onClick={action} className="m-2">
        <i className="fas fa-edit"></i>{title}

    </Button>              
  )
}

export default EditButton