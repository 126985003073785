import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  pets: [],
  current_page:0,
  value:0,
  messageSuccess:null,
  messageError:null,
  pet:null,
  customer:null,
  isEditing:false, 
  total_items:0,
  total_pages:1
}

export const petSlice = createSlice({
  name: 'pet',
  initialState,
  reducers: {
    selectedPet:(state,{payload})=>{
      state.pet=payload.pet
    },
    loadPets:(state,{payload})=>{
      state.pets=payload.pets
      state.current_page=payload.current_page
      state.total_items=payload.total_items
      state.total_pages=payload.total_pages
    },
    setPet:(state,{payload})=>{
      state.pets.push(payload)
    },
    updatePet:(state,{payload})=>{
      state.pets=state.pets.map(item=>{
        if (item.pk===payload.pk){
          return payload
        }
        return item
      })
      state.isEditing=false
    },
    deletePet:(state,{payload})=>{
      state.pets=state.pets.filter(item=>item.pk!==payload)
      state.isEditing=false
    },
    setEditing:(state,{payload})=>{
      state.isEditing=true
    },
    notEditing:(state,{payload})=>{
      state.isEditing=false
    },
    setCustomer:(state,{payload})=>{
      state.customer=payload
    },
    selectPet:(state,{payload})=>{
      state.pet=payload
      state.isEditing=false
    },
  },
})

// Action creators are generated for each case reducer function
export const {selectPet, setEditing, setCustomer,selectedPet, updatePet, resetMessages, deletePet, loadPets, setMessage, setPet } = petSlice.actions
