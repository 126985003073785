import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  pets:[],
  veterinarians:[]
}

export const listSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    loadPets:(state,{payload})=>{
        state.pets = payload.pets
        state.isLoading=false
    },
    setLoading:(state,{payload})=>{
      state.isLoading=payload
    },
    
    loadVeterinarians:(state,{payload})=>{
        state.veterinarians = payload.veterinarians
        state.isLoading=false
    },
  },
})

// Action creators are generated for each case reducer function
export const {loadPets, loadVeterinarians, setLoading } = listSlice.actions
