import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Cropper from 'react-easy-crop';
import getCroppedImg from './utils/CropImage';

export const CropEasy = ({photoUrl, setPhotoUrl, setFile, show, setOpenCrop}) => {
  const [crop, setCrop]=useState({x:0, y:0})
  const [zoom, setZoom]=useState(1)
  const [rotation, setRotation]=useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels]=useState(null)

  const cropComplete=(croopedArea,croppedAreaPixels )=>{
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const cropImage=async()=>{
    try{
      const {file, url}=await getCroppedImg(photoUrl, croppedAreaPixels, rotation)
      setPhotoUrl(url)
      setFile(file)
      setOpenCrop(false)
    }catch(error){
      console.log(error,"err")
    }
  }
  return (
    <Modal show={show}>
    <Modal.Header closeButton onClick={()=>setOpenCrop(false)}>
      <Modal.Title>Foto Editor</Modal.Title>
    </Modal.Header>
    <Modal.Body style={{background:'red', height:300}}>
        <Cropper image={photoUrl} crop={crop} rotation={rotation} zoom={zoom} aspect={4 / 4} onZoomChange={setZoom} onRotationChange={setRotation} onCropChange={setCrop} onCropComplete={cropComplete}/>
    </Modal.Body>
    <Modal.Footer>
      <Row>
        
        <Col md={12}>
          <Form.Label>Zoom: {zoomPercent(zoom)}</Form.Label>
          <Form.Range min={1} step={0.1} max={3} value={zoom} onChange={(e)=>setZoom(e.target.value)} />
        </Col>
        <Col  md={12}>
          <Form.Label>Rotation: {rotation}</Form.Label>
          <Form.Range min={0} max={360} value={rotation} onChange={(e)=>setRotation(e.target.value)} />
        </Col>
        
      </Row>

      <Button variant="secondary" onClick={()=>setOpenCrop(false)}>
        Cancelar
      </Button>
      <Button variant="danger" onClick={cropImage}>
        Crop
      </Button>
    </Modal.Footer>
  </Modal>
  )
}

const zoomPercent=(value)=>{
  return `${Math.round(value*100)}%`
}
