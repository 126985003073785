export const validations_form_login = (form) => {
  let error = {};
  if (!form.email.trim()) {
    error.email = "El campo email es requerido!";
  }

  if (!form.password.trim()) {
    error.password = "El campo password es requerido!";
  }

  return error;
};

export const validations_form_owners = (form) => {
  let error = {};
  if (!form.name.trim()) {
    error.name = "El campo nombre es requerido!";
  }

  if (!form.identification_card.trim()) {
    error.identification_card = "El campo cedula es requerido!";
  }

  if (!form.location.trim()) {
    error.location = "El campo ubicacion es requerido!";
  }

  if (!form.phone.trim()) {
    error.phone = "El campo telefono es requerido!";
  }

  return error;
};

export const validations_form_veterinarians = (form) => {
  let error = {};
  if (!form.name.trim()) {
    error.name = "El campo nombre es requerido!";
  }

  if (!form.password.trim()) {
    error.password = "El campo contraseña es requerido!";
  }

  if (!form.email.trim()) {
    error.email = "El campo correo es requerido!";
  }
  return error;
};

export const validations_form_pets = (form) => {
  let error = {};
  if (!form.name.trim()) {
    error.name = "El campo nombre es requerido!";
  }

  if (!form.number_chip.trim()) {
    error.number_chip = "El campo nro del chip es requerido!";
  }

  if (!form.race.trim()) {
    error.race = "El campo raza es requerido!";
  }

  if (!form.date_of_birth.trim()) {
    error.date_of_birth = "El campo fecha de nacimiento es requerido!";
  }

  if (!form.species.trim()) {
    error.species = "El campo especie requerido!";
  }
  
  if (!form.status.trim()) {
    error.status = "El campo status es requerido!";
  }

  if (!form.species.trim()) {
    error.species = "El campo especie requerido!";
  }
  
  if (!form.sex.trim()) {
    error.sex = "El campo sexo es requerido!";
  }

  return error;
};
