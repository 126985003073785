import React from 'react'
import { Image } from 'react-bootstrap'
import { COLORS } from '../../helpers/colors'
import avatar_pet from '../../images/avatar_pet.png'
import lost from '../../images/iconos/lost.png'
import './AvatarPet.css'

const AvatarPet = ({pet_image , pet_name, pet_status}) => {
  return (
    <div className='card_pet'>
        <Image src={pet_image? pet_image:avatar_pet}  style={{ borderRadius:'50%', height:170, width:170, objectFit:'cover', objectPosition:'center'}}/>
        <p className='pet_name' style={{color:COLORS.red}}>{pet_name? pet_name:''}</p>
        {pet_status!=="NOTLOST" && 
        <p style={{color:COLORS.black, fontSize:'1.6rem', fontWeight:'bold'}} className='d-flex justify-content-center align-items-center' >
            <Image src={lost} className="me-2" style={{ height:30, width:30}}/>
            Extraviado!
        </p>  
        }
    </div>
  )
}

export default AvatarPet