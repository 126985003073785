import React from 'react'
import { Spinner } from 'react-bootstrap'
import { COLORS } from '../../helpers/colors'

const Loading = () => {
  return (
    <div className='text-center' style={{color:COLORS.red}}>
        <Spinner />
        <h3 className='mt-3' >CARGANDO...</h3>
    </div>
  )
}

export default Loading