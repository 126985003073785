
export const validations_form_veterinarians = (form) => {
  let error = {};
  if (!form.name.trim()) {
    error.name = "El campo nombre es requerido!";
  }

  if (form.pk==null && !form.password.trim() ) {
    error.password = "El campo contraseña es requerido!";
  }

  if (!form.enrollment.trim()) {
    error.enrollment = "El campo matricula es requerido!";
  }

  if (!form.username.trim()) {
    error.username = "El campo username es requerido!";
  }

  if (!form.department.trim()) {
    error.department = "El campo departamento es requerido!";
  }

  if (isNaN(form.enrollment.trim())){
    error.enrollment = "El campo matricula debe ser un numero es requerido!";
  }

  if (!form.email.trim()) {
    error.email = "El campo correo es requerido!";
  }
  return error;
};


export const validations_form_customers = (form) => {
  let error = {};
  if (!form.name.trim()) {
    error.name = "El campo nombre es requerido!";
  }

  if (!form.identification_card.trim()) {
    error.identification_card = "El campo cedula es requerido!";
  }

  if (!form.location.trim()) {
    error.location = "El campo ubicacion es requerido!";
  }

  if (form.other_phone[0]==="") {
    error.other_phone = "El campo phone es requerido!";
  }

  return error;
};