import { notEditing, setEditing, loadCustomers, deleteCustomer, updateCustomer, setCustomer} from "./slices/customerSlice";
import { customers_api, customer_delete_api, customer_find_api, customer_post_api, customer_put_api, pet_post_api } from '../apps/customers/api/customers_api';
import { setMessage } from "./slices/messageSlice";
  
export const customerFindThunk =  (name) => {
  return async (dispatch)=>{
     // eslint-disable-next-line
      const res = await customer_find_api(name)
      dispatch(loadCustomers({customers:res.results, current_page:res.current_page, total_items:res.count , total_pages:res.total_pages}))
  }    
};

  export const loadCustomersThunk =  (page = 1, page_size = 10) => {
    return async (dispatch)=>{
        const res = await customers_api(page,page_size);
        dispatch(loadCustomers({customers:res.results, current_page:res.current_page, total_items:res.count , total_pages:res.total_pages}))
    }    
  };


  export const createCustomerThunk =  (form) => {
    return async (dispatch)=>{
      dispatch(setEditing())
      try{  
        const res = await customer_post_api(form);        
        const customer=res.data
        dispatch(setCustomer({...customer}))
        dispatch(setMessage({message:"Cliente Creado!",typeMessage:'success'}))
        }catch(e){
          dispatch(setMessage({message:"No se pudo crear al cliente",typeMessage:'error'}))
        }
      dispatch(loadCustomersThunk())
    }    
  };

  export const createCustomerPetThunk =  (form) => {
    return async (dispatch)=>{
      dispatch(setEditing())
      try{  
          await pet_post_api(form);    
          dispatch(setMessage({message:"Mascota Creada!",typeMessage:'success'}))
        }catch(e){
          dispatch(setMessage({message:"No se pudo crear la mascota",typeMessage:'error'}))        
        }
      dispatch(notEditing())
    }    
  };

  export const updateCustomerThunk =  (customer) => {
    return async (dispatch)=>{
        dispatch(setEditing())
        try{
          const res = await customer_put_api({...customer});    
          dispatch(updateCustomer({...res.data}))
          dispatch(setMessage({message:"Cliente Actualizado!",typeMessage:'success'}))
        }catch(e){
          dispatch(setMessage({message:"No se pudo actualizar al cliente",typeMessage:'error'}))        
        }
        dispatch(notEditing())
    }    
  };

  export const deleteCustomerThunk = (pk) => {
    return async (dispatch)=>{
        dispatch(setEditing())
         // eslint-disable-next-line
        const res=await customer_delete_api(pk)
        try{
          dispatch(deleteCustomer(pk))
        }catch(error){
          dispatch(setMessage({message:"Veterinario no pudo ser eliminado!!",typeMessage:'error'}))
        }
        dispatch(notEditing())
    }    
  };