import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../components/banner/Banner";
import { LayoutPublic } from "../components/layout/LayoutPublic";

const NoScreen = () => {
  return (
    <LayoutPublic fluid>
      <Container>
        <Row>
          <Col>
            <Banner />
          </Col>
        </Row>
        <Row>
          <h2 className="text-center">Pagina no encontrada!!</h2>
        </Row>
      </Container>
    </LayoutPublic>
  );
};

export default NoScreen;
