import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { findVeterinaryThunks, loadVeterinaryThunks } from "../../../store/veterinaryThunks";
// import { veterinarian_find_api } from "../api/veterinarians_api";

const FormFind = () => {
  const dispatch=useDispatch()
  const [values, setValues] = useState({ name: "" });

  const handleInput = (e) => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
  };



  const buscar = async(e)=>{
    e.preventDefault()
    if(values.name!==''){
      dispatch(findVeterinaryThunks(values.name))
    }else{
      dispatch(loadVeterinaryThunks());
    }
    
    
    // setVeterinarians(res);
    // setCurrentPage(1);
  }

  return (
    <Form onSubmit={(e)=>buscar(e)}>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Introdusca el nombre."
          aria-label="Introdusca el nombre."
          aria-describedby="basic-addon2"
          type="text"
          name="name"
          value={values.name}
          onChange={handleInput}
        />
        <Button variant="danger" id="button-addon2" type="submit">
          Buscar
        </Button>
      </InputGroup>
    </Form>
  );
};

export default FormFind;
