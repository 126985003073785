import React from "react";
import { Col, Row } from "react-bootstrap";
import { COLORS } from "../../helpers/colors";
import { TITLES_PET, TITLES_PET_SEX } from "../../helpers/titles_constants";

const NPetInfo = ({ pet }) => {
  const data = [
    { title: TITLES_PET.nro_chip, text: pet.number_chip },
    { title: TITLES_PET.tattoo, text: pet.tattoo },
    { title: TITLES_PET.speci, text: pet.species },
    { title: TITLES_PET.raze, text: pet.race },
    { title: TITLES_PET.sex, text: TITLES_PET_SEX[pet.sex] },
    { title: TITLES_PET.birthday, text: pet.date_of_birth },
    { title: TITLES_PET.observations, text: pet.observations },
  ];
  return (
    <Row className="justify-content-center">
        {data.map((item,key) => (
        <Row key={key} className="justify-content-center">
          <Col xs="5" md="4" className="p-0 pe-2">
            <label
              xs="5"
              md="3"
              className={
                item.title === "Fecha de Nacimiento"||item.title === "Observaciones" ? "align-items-center p-2 text-mobile" : "p-2 align-items-center"
              }
              style={{
                background: COLORS.red,                
                color: COLORS.white,
                width: "100%",
                fontWeight: "bold",                
                fontSize:"0.9rem",
                minHeight:'40px'
              }}
            >
              {item.title}:
            </label>
          </Col>
          <Col xs="7" md="5" className="p-0 mb-2">
            <label
              className="p-2"
              style={{
                margin: 0,
                background: COLORS.light_blue,
                color: COLORS.black,
                width: "100%",
                fontSize:"0.9rem",
                minHeight:'40px'
              }}
            >
              {item.text}
            </label>
          </Col>
        </Row>
      ))}
    </Row>
  );
};

export default NPetInfo;
