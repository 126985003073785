import emailjs from '@emailjs/browser';
import { YOUR_PUBLIC_KEY, YOUR_SERVICE_ID, YOUR_TEMPLATE_ID } from "../config/api_email";

export const enviarEmail=(form, actualizar=false)=>{
    
    var message_email={
      to_name: form.name,
      from_name:'Ubipets',
      new_action:actualizar? 'editado':'creado',
      new_username: form.username,
      new_password: form.password,
      email_destino: form.email,
    }

    emailjs.send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, message_email , YOUR_PUBLIC_KEY)
    .then((result) => {        
        console.log("succefully send email")
    }, (error) => {
        console.log("error enviar email",error.text);
    });
  }