import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: 'checking', /*checking authenticated */
  pk:0,
  email:'',
  username:'',
  token:'',
  role:''
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signin: (state, action) => {
      const {username, role, email, pk}=action.payload
      state.pk=pk
      state.status='authenticated';
      state.email=email;
      state.username= username;
      state.token='sadasdasde23423423'
      state.role=role
    },
    logout: (state) => {
      state.pk=0
      state.status= 'not-authenticated';
      state.email='';
      state.username='';
      state.token='';
      state.role='PUBLIC'
    },
    checkingCredentials: (state) => {
      state.status='checking'
    },
  },
})

// Action creators are generated for each case reducer function
export const { checkingCredentials, logout, signin } = authSlice.actions