import React from 'react'
import { Button } from 'react-bootstrap'

const DeleteButton = ({action=()=>{}, title=""}) => {
  return (    
    <Button variant="danger" onClick={action} className="m-2">
        <i className="fas fa-trash-alt"></i>{title}
    </Button>              
  )
}

export default DeleteButton