import axiosAPI from "../../../api/axiosConfig"


const API_PETS="mascot/pets"

export const pets_api = async(page=1, page_size=10)=>{    
    const res = await axiosAPI.get(`/${API_PETS}/?page=${page}&page_size=${page_size}`)
    return res.data
}

export const pet_post_api = async(data)=>{    
    try{
        const res = await axiosAPI.post(`/${API_PETS}/create/`,data,{headers: { "Content-Type": "multipart/form-data" }})
        return res
    }catch(err){
        return false
    }   
}

export const pet_delete_api = async(id)=>{    
    try{
        // eslint-disable-next-line
        const res = await axiosAPI.delete(`/${API_PETS}/${id}/delete/`)        
        return true
    }catch(err){
        return false
    }   
}

export const pet_put_api = async(pk,data)=>{    
    try{
        const res = await axiosAPI.patch(`/${API_PETS}/${pk}/update/`,data, {headers: { "Content-Type": "multipart/form-data" }})
        return res
    }catch(err){
        return false
    }    
}

export const pet_find_api = async(data)=>{   
    try{
        const res = await axiosAPI.get(`/${API_PETS}/find?search=${data}`)
        return res.data
    } catch(err){
        return []
    }   
}

export const pets_customer_api = async(id)=>{    
    const res = await axiosAPI.get(`/${API_PETS}/propertyuser/${id}/`)
    return res.data   
}

const API_CUSTOMERS="mascot/propertyusers"

export const customers_api = async(limit=10, offset=1, all=false)=>{    
    try{
        if (all===true){
            const res = await axiosAPI.get(`/${API_CUSTOMERS}/`)
            return res.data   
        }else{
            const res = await axiosAPI.get(`/${API_CUSTOMERS}/?limit=${limit}&offset=${offset}`)
            return res.data   
        }   
    } catch(err){
        return []
    }   
}

export const customer_get_api = async(id)=>{    
    const res = await axiosAPI.get(`/${API_CUSTOMERS}/${id}/`)
    return res.data   
}

export const pet_get_api = async( pk)=>{ 
    const res = await axiosAPI.get(`/${API_PETS}/${pk}/`)    
    return res.data
}