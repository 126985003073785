import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useForm } from "../../../hooks/useForm";
import { createCustomerThunk, updateCustomerThunk } from "../../../store/customerThunks";
import { validations_form_customers } from "../helpers/validations";
import GMap from "../../../components/mapas_pet/gmap";
import { useJsApiLoader } from "@react-google-maps/api";
import { mapOptions } from "../../../config/api_map";

const form_default = {
  pk: null,
  name: "",
  identification_card: "",
  location: "",
  latitude:"",
  longitude:"",
  phone:"",
  other_phone: [""],
  profile: 5,
};

const FormAdd = ({ closeModal, form_edit = null }) => {

  const {
    form, 
    setForm,
    handleChangeForm,
    handleBlur,
    errors,
    setErrors,
    resetForm,
  } = useForm(form_edit ? {...form_edit,'profile':form_edit.profile.pk, 'other_phone':form_edit.other_phone===null? [""]:form_edit.other_phone} : form_default, validations_form_customers);
  const [validated, setValidated] = useState(false);
  const dispatch=useDispatch()
  
  // const locationState=useRef(form_edit && form_edit.latitude!==null ? {lat:form_edit.latitude,lng:form_edit.longitude}:{ lat: -17.802326570311045, lng: -63.12791448778615 })
  // const locationState=useRef({ lat: -17.802326570311045, lng: -63.12791448778615 })

  const handleSubmit = async (e) => {
    e.preventDefault();   
    
    const formErrors = validations_form_customers(form);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);  
      return
    }

    setErrors(formErrors);
    if (form.pk) {
      Swal.fire({
            title: "Editar Cliente!",
            text: "Estas seguro de realizar esta accion?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, crealo!",
          }).then(async (result) => {
            form['other_phone']=form.other_phone.filter(item=>item!=="")
            const form_local=form

            if (result.isConfirmed) {
              dispatch(updateCustomerThunk(form_local))        
            }
        })
      resetForm();
      closeModal();
      setValidated(false);
      return;
    }else{
      
      Swal.fire({
        title: "Agregar Cliente!",
        text: "Estas seguro de realizar esta accion?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, crealo!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const form_local=form
          form_local['other_phone']=form.other_phone.filter(item=>item!=="")
          dispatch(createCustomerThunk(form_local))        
        }
    })
      resetForm();
      closeModal();
      setValidated(false);
      return
    }        
    
  };
  const capturarPosition=(latLong)=>{
    setForm({...form, latitude:latLong.lat(), longitude:latLong.lng()})
  }

  const handleAddPhone=()=>{
    setForm({...form, other_phone:[...form.other_phone,""]})
  }

  const handleChangePhone=(e,i)=>{
    const other_phone=form.other_phone
    other_phone[i]=e.target.value
    setForm({...form, other_phone:other_phone})
    
  }

  const {isLoaded}=useJsApiLoader({
    id: mapOptions.googleMapApiKey,
    googleMapsApiKey: mapOptions.googleMapApiKey,
  })
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            name="name"
            type="text"
            value={form.name}
            onChange={handleChangeForm}
            required
            onBlur={handleBlur}
          />
          {errors.name && <p style={styles_error}>{errors.name}</p>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
          <Form.Label>Cedula</Form.Label>
          <Form.Control
            name="identification_card"
            type="text"
            value={form.identification_card}
            onChange={handleChangeForm}
            required
            onBlur={handleBlur}
          />
          {errors.identification_card && (
            <p style={styles_error}>{errors.identification_card}</p>
          )}
        </Form.Group>
        {/* :[-17.8028544, -63.127552] */}
        <Form.Group style={{height:300, width:'100%'}}>

          {/* <MapLeaflet  langlon={locationState.current} capturarPosition={capturarPosition}/> */}
          <GMap isLoaded={isLoaded} capturarPosition={capturarPosition}  />
          
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea3">
          <Form.Label>Ubicacion</Form.Label>
          <Form.Control
            name="location"
            as="textarea"
            rows={3}
            value={form.location}
            onChange={handleChangeForm}
            required
            onBlur={handleBlur}
          />
          {errors.location && <p style={styles_error}>{errors.location}</p>}
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
          <Form.Label>Telefono</Form.Label>
          {form.other_phone.map((item,i)=>
          (<Form.Control
            key={i}
            name="phone"
            type="text"
            value={item}
            onChange={(e)=>handleChangePhone(e,i)}
            required
            onBlur={handleBlur}
            className="mb-2"
          />)
          )}          
          <Button variant="danger" onClick={handleAddPhone}>Agregar telefono</Button>
          {errors.other_phone && <p style={styles_error}>{errors.other_phone}</p>}
        </Form.Group>

        <Form.Group className="justify-content-end" style={{ display: "flex" }}>
          <Button variant="danger" type="submit">
            Guardar
          </Button>
          <Button variant="secondary" onClick={closeModal} className="ms-2">
          Cancelar
        </Button>
        </Form.Group>
      </Form>
    </>
  );
};

let styles_error = {
  fontWeight: "bold",
  color: "#dc3545",
};

export default FormAdd;
