import { setMessage } from "../slices/messageSlice";

import { vaccination_delete_api, vaccination_patch_api, vaccination_post_api, vaccinations_api, vaccinations_by_pet_api } from "../../apps/drugs/api/vaccinationApi";
import { loadVaccionation, setLoading } from "../slices/vaccionationSlice";


export const loadVaccionationThunks =  (page, limit) => {
    return async (dispatch)=>{
       // eslint-disable-next-line
        const res = await vaccinations_api(page, limit);
        dispatch(loadVaccionation({vaccination:res.results, current_page:res.current_page, total_items:res.count , total_pages:res.total_pages }))
    }    
};

  export const createVaccinationThunk =  (form) => {
    return async (dispatch)=>{
      dispatch(setLoading(true))
      try{  
         // eslint-disable-next-line
        const res = await vaccination_post_api(form);
        dispatch(loadVaccionationThunks(1,10))
        dispatch(setMessage({message:"ok save",typeMessage:'success'}))

        }catch(e){
            dispatch(setMessage({message:"errror save",typeMessage:'error'}))
        }
    }    
  };

  export const updateVaccinationThunk =  (pk,form) => {
    return async (dispatch)=>{
      dispatch(setLoading(true))
      try{  
         // eslint-disable-next-line
        const res = await vaccination_patch_api(pk,form);
        dispatch(loadVaccionationThunks(1,10))
        dispatch(setMessage({message:"ok edit",typeMessage:'success'}))

        }catch(e){
            dispatch(setMessage({message:"errror edit",typeMessage:'error'}))
        }
    }    
  };

  export const deleteVaccinationThunk =  (pk) => {
    return async (dispatch)=>{
      dispatch(setLoading(true))
      try{  
         // eslint-disable-next-line
        const res = await vaccination_delete_api(pk);
        dispatch(loadVaccionationThunks(1,10))
        dispatch(setMessage({message:"ok delete",typeMessage:'success'}))

        }catch(e){
            dispatch(setMessage({message:"errror delete",typeMessage:'error'}))
        }
    }    
  };



  export const loadVaccinationsByPetThunks =  (pk, page) => {
    return async (dispatch)=>{
        dispatch(setLoading(true))
         // eslint-disable-next-line
        const res = await vaccinations_by_pet_api(pk, page);
        dispatch(loadVaccionation({vaccination:res.results, current_page:res.current_page, total_pages:res.total_pages }))
    }    
  };

  export const createVaccinationByPetThunk =  (idpet, form) => {
    return async (dispatch)=>{
      dispatch(setLoading(true))
      try{  
         // eslint-disable-next-line
        const res = await vaccination_post_api(form);
        dispatch(loadVaccinationsByPetThunks(idpet, 1))
        dispatch(setMessage({message:"ok save",typeMessage:'success'}))

        }catch(e){
            dispatch(setMessage({message:"errror save",typeMessage:'error'}))
        }
    }    
  };

  export const updateVaccinationByPetThunk =  (pk,form, idpet) => {
    return async (dispatch)=>{
      dispatch(setLoading(true))
      try{  
         // eslint-disable-next-line
        const res = await vaccination_patch_api(pk,form);
        dispatch(loadVaccinationsByPetThunks(idpet,1))
        dispatch(setMessage({message:"ok edit",typeMessage:'success'}))

        }catch(e){
            dispatch(setMessage({message:"errror edit",typeMessage:'error'}))
        }
    }    
  };


  export const deleteVaccinationByPetThunk =  (pk, idpet) => {
    return async (dispatch)=>{
      dispatch(setLoading(true))
      try{  
         // eslint-disable-next-line
        const res = await vaccination_delete_api(pk);
        dispatch(loadVaccinationsByPetThunks(idpet,1))
        dispatch(setMessage({message:"ok delete",typeMessage:'success'}))

        }catch(e){
            dispatch(setMessage({message:"errror delete",typeMessage:'error'}))
        }
    }    
  };

