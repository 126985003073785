import { GoogleMap, Marker } from '@react-google-maps/api'
import React, { useState } from 'react'


const GMap = (props) => {
    const{isLoaded, center=null, capturarPosition=()=>{} }=props
    // eslint-disable-next-line
    const [another,setAnother]=useState({ lat: -17.7648184, lng: -63.172176 })

    const containerStyle={
        width:'100%',
        height:'300px',
    }
// eslint-disable-next-line
    const getLocation=()=>{
        navigator.geolocation.getCurrentPosition(function(position) {
            // eslint-disable-next-line
            const dat=[{name:"local", location:{lat:position.coords.latitude,lng:position.coords.longitude}}]
            // setAnother(dat[0].location)        
          });
    }
    const [markerCapture, setMarkerCapture]=useState([])
    const onMapClick=(e)=>{
        const dat=[{name:"Me", location:{lat:e.latLng.lat(),lng:e.latLng.lng()}}]
        setMarkerCapture(dat)
        capturarPosition(e.latLng)
    }
    return isLoaded && ( 
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center? center:another}
            zoom={15}
            onClick={onMapClick}
            >
            {center && <Marker position={center}/>}
            {
                markerCapture && markerCapture.map(item=>(<div key={item.name} ><Marker  position={item.location} label={item.name}/></div>))
            }
          </GoogleMap>
      )
}

export default GMap