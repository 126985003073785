import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from './slices/authSlice'

import { customerSlice } from './slices/customerSlice'
import { veterinarianSlice } from './slices/veterinarianSlice'
import { petSlice } from './slices/petSlice'
import { vaccionationSlice } from './slices/vaccionationSlice'

import { listSlice } from './slices/listSlice'

import { messageSlice } from './slices/messageSlice'

export const store = configureStore({
  reducer: {
    'veterinarian':veterinarianSlice.reducer,
    'auth':authSlice.reducer,
    'customer':customerSlice.reducer,
    'pet':petSlice.reducer,
    'message':messageSlice.reducer,
    'vaccination':vaccionationSlice.reducer,
    'list':listSlice.reducer,
  },
})