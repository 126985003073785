import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  vaccinations: [],
  current_page:0,
  total_pages:0,
  total_items:0,
}

export const vaccionationSlice = createSlice({
  name: 'vaccination',
  initialState,
  reducers: {
    loadVaccionation:(state,{payload})=>{
        state.vaccinations = payload.vaccination
        state.current_page=payload.current_page
        state.total_pages=payload.total_pages
        state.total_items=payload.total_items
        state.isLoading=false
    },
    setLoading:(state,{payload})=>{
      state.isLoading=payload
    },    
  },
})

// Action creators are generated for each case reducer function
export const {loadVaccionation, setLoading } = vaccionationSlice.actions
