import React from 'react'
import { Image } from 'react-bootstrap'
import logo_ubipets from  '../../images/logo_ubipets.png'
import './Banner.css'
const Banner = () => {
  return (
    <div className='banner'>
          <Image fluid style={{width:'100%', height:'auto'}}
        src={logo_ubipets}
      />
    </div>
  )
}

export default Banner