import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { COLORS } from "../../helpers/colors";
import { TITLES_CUSTOMER } from "../../helpers/titles_constants";
import "./OwnerInfo.css";

const NOwnerInfo = ({ owner }) => {
  const data = [
    { title: TITLES_CUSTOMER.name, text: owner.name },
    { title: TITLES_CUSTOMER.ci, text: owner.identification_card },
    { title: TITLES_CUSTOMER.phone, text: owner.other_phone },
  ];


  return (
    <Row className="justify-content-center">
      {data.map((item,key) => (
        <Row key={key} className="justify-content-center pb-1">
          <Col xs="5" md="4" className="p-0 pe-2">
            <label
              key={key}
              xs="6"
              md="3"
              className={
                item.title === TITLES_CUSTOMER.name||item.title === TITLES_CUSTOMER.ci || item.title === TITLES_CUSTOMER.phone ? "text-mobile p-2 align-items-center" : "p-2 align-items-center"
              }
              style={{
                background: COLORS.gray,
                color: COLORS.white,
                width: "100%",
                fontWeight: "bold",                
                fontSize:"0.9rem",
                minHeight:'40px'
              }}
            >
              {item.title}:
            </label>
          </Col>
          <Col xs="7" md="5" className="p-0">
            {item.title===TITLES_CUSTOMER.phone? 
             item.text.map((phone,keyphone)=>(<label
              style={{
                margin: 0,
                background: COLORS.light_blue,
                color: COLORS.black,
                width: "100%",
                fontSize:"0.9rem",
                minHeight:'40px'
              }}
              className="p-2 mb-2"
              key={keyphone}
            >
              
              <Container className="p-0" >
                  <Row
                   className="p-0 m-0"
                   style={{
                     color: COLORS.black,
                     fontSize: "1rem",
                   }}
                 >
                   <Col className="p-0 m-0 d-flex justify-content-between">
                     <label>{phone}</label>
                     <a href={`https://api.whatsapp.com/send?phone=591${phone}`} target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
  <i style={{color: COLORS.green, fontWeight: 'bold', fontSize: '1.2rem'}} className="fa fa-whatsapp" aria-hidden="true"></i>
</a>
                     {/* // eslint-disable-next-line */}
                   </Col>
                 </Row>
               </Container>
               
            </label>)):(<label
              style={{
                margin: 0,
                background: "#FCF7F7",
                color: "#000",
                width: "100%",
                fontSize:"0.9rem",
                minHeight:'40px'
              }}
              className="p-2"
            >
              {item.text}
            </label>)}
          </Col>
        </Row>
      ))}
    </Row>
  );
};

export default NOwnerInfo;
