import React, { useEffect, useState } from 'react'
import { Button, Form, Image, Badge } from 'react-bootstrap'
import { useForm } from '../../../hooks/useForm';
import { validations_form_pets } from '../helpers/validations';
// eslint-disable-next-line
import avatar_pet from "../../../images/avatar_pet.png";
import Swal from 'sweetalert2';
import { customers_api} from '../api/pets_api';
import { createPetThunk, updatePetThunk } from '../../../store/petThunks';
import { useDispatch, useSelector } from 'react-redux';
import { PET_RAZE_LIST, PET_SPECIE_LIST, TITLES_PET, TITLES_PET_SEX } from '../../../helpers/titles_constants';
import { CropEasy } from '../components/crop/CropEasy';


const form_default = {
  pk: "",
  date_of_birth: "",
  name: "",
  number_chip: "",
  observations: "",
  race: "",
  sex: "",
  species: "",
  status: "NOTLOST",
  tattoo: "",
  reference: null,
  property_user: "",
  profile:""
};

const FormAdd = ({form_edit=null,closeModal}) => {
    
  const {
    form,
    setForm,
    handleChangeForm,
    handleBlur,
    errors,
    setErrors,
    resetForm,
  } = useForm(form_edit? form_edit:{...form_default}, validations_form_pets);

  const [validated, setValidated] = useState(false);
  const [owners, setOwners] = useState({results:[]})
  const [file, setFile] = useState(null);
  // eslint-disable-next-line
  const [selectImage,setSelectImage]=useState(false)
  const [openCrop,setOpenCrop]=useState(false)
  const [photoUrl,setPhotoUrl]=useState(form_edit?.reference)

  const dispatch=useDispatch()
  // eslint-disable-next-line
  const {isEditing}=useSelector(state=>state.pet)

  const handleChangeImagen = (e) => {
    const file=e.target.files[0]
    if (file){
      setPhotoUrl(URL.createObjectURL(file))
      setFile(file);
      setSelectImage(true)    
      setOpenCrop(true)
    }
  };

  const getOwners= async ()=>{
    const res = await customers_api(10,10,true)
    setOwners(res)
  }

  useEffect(() => {
    getOwners()    
  }, [])

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validations_form_pets(form);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);  
      return
    }
    if (file){
      setForm({...form,'reference':file})
    }

    setErrors(formErrors);
    if (form.pk) {
      const form_local={...form, property_user:form.property_user.pk, profile:form.profile.pk}
      
      Swal.fire({
            title: "Editar Mascota!",
            text: "Estas seguro de realizar esta accion?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, crealo!",
          }).then(async (result) => {
            if (result.isConfirmed) {
              dispatch(updatePetThunk(form.pk, {...form_local,'reference':file}))        
            }
        })
      resetForm();
      closeModal();
      setValidated(false);
      return;
    }else{
      
      Swal.fire({
        title: "Agregar Mascota!",
        text: "Estas seguro de realizar esta accion?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, crealo!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let form_local=form
          if (file){
            form_local={...form, 'reference':file}
          }
          
          dispatch(createPetThunk(form_local))        
        }
    })
      resetForm();
      closeModal();
      setValidated(false);
      return
    }        
    
  };

  const handleChangeAutoComplete=(e)=>{
    setForm({...form, [e.target.name]:e.target.value})
  }

  const selectAutoComplete=(name, value)=>{
    setForm({...form, [name]:value})
  }

  return (
    <>
    <Form encType="multipart/form-data" noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea3"
          >
            <Form.Label>Dueño</Form.Label>
            {!form.pk && <Form.Select
              name="property_user"
              type="text"
              value={form.property_user}
              onChange={handleChangeForm}
              required
              onBlur={handleBlur}
            >
              {
                owners.results.map((item_owner, index_owner)=>(
                  <option value={item_owner.pk} key={index_owner}>{item_owner.name}</option>
                ))
              }
              
            </Form.Select>}

            {form.pk &&
              <Form.Select
              name="property_user"
              type="text"
              value={form.property_user.pk} 
              required
              onBlur={handleBlur}
              disabled={true}
            >  
                  <option value={form.property_user.pk} >{form.property_user.name}</option>              
            </Form.Select>
            }
            {errors.property_user && (
              <p style={styles_error}>{errors.property_user}</p>
            )}
          </Form.Group>

        <Form.Group
          className="mb-3"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>Imagen</Form.Label>
          <Form.Control
            name="imagen"
            type="file"
            onChange={handleChangeImagen}
            required
            onBlur={handleBlur}
          />
          {errors.imagen && <p style={styles_error}>{errors.imagen}</p>}
          <div className='justify-content-center' style={{display:'flex'}}>
          <Image
            style={{ borderRadius:'50%',height:150, width: 150, objectFit:'cover', objectPosition:'center' }}
            src={photoUrl}
            className="mt-2 text-center"
          />
          </div>
        </Form.Group>
        
        <Form.Group
          className="mb-3"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>{TITLES_PET.name}</Form.Label>
          <Form.Control
            name="name"
            type="text"
            value={form.name}
            onChange={handleChangeForm}
            required
            onBlur={handleBlur}
          />
          {errors.name && <p style={styles_error}>{errors.name}</p>}
        </Form.Group>

        <Form.Group
          className="mb-3"
          controlId="exampleForm.ControlTextarea3"
        >
          <Form.Label>{TITLES_PET.status}</Form.Label>
          <Form.Select
            name="status"
            type="text"
            value={form.status}
            onChange={handleChangeForm}
            onBlur={handleBlur}
          >
            <option value="NOTLOST">----------</option>
            <option value="LOST">Extraviado</option>
          </Form.Select>
        </Form.Group>

        <Form.Group
          className="mb-3"
          controlId="exampleForm.ControlInput2"
        >
          <Form.Label>{TITLES_PET.nro_chip}</Form.Label>
          <Form.Control
            name="number_chip"
            type="text"
            value={form.number_chip? form.number_chip:""}
            onChange={handleChangeForm}
            onBlur={handleBlur}
          />
        </Form.Group>
        <Form.Group
          className="mb-3"
          controlId="exampleForm.ControlTextarea3"
        >
          <Form.Label>{TITLES_PET.tattoo}</Form.Label>
          <Form.Control
            name="tattoo"
            type="text"
            value={form.tattoo? form.tattoo:""}
            onChange={handleChangeForm}
            onBlur={handleBlur}
          />
        </Form.Group>
        
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea3" >
        <Form.Label>{TITLES_PET.speci}</Form.Label>        
        <Form.Control
          name="species"
          type="text"
          value={form.species}
          onChange={handleChangeAutoComplete}
          required
          autoComplete="off"
        />
        { form.species!=="" &&
        <div className="d-flex mt-2 autocompleted_container" >
        {PET_SPECIE_LIST.filter(item_raze=>{
            const searchTerm=form.species.toLowerCase()
            const raze_name=item_raze.toLowerCase()
            return searchTerm && raze_name.startsWith(searchTerm) && raze_name!==searchTerm
          }).slice(0,3).map((item_raze)=>(
            <h5 className="selectable pe-1" onClick={()=>selectAutoComplete('species',item_raze)} key={item_raze}><Badge bg="secondary">{item_raze}</Badge></h5>
          ))}
        </div>
        }
        {errors.species && (
          <p style={styles_error}>{errors.species}</p>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea3" >
        <Form.Label>{TITLES_PET.raze}</Form.Label>
        
        <Form.Control
          name="race"
          type="text"
          value={form.race}
          onChange={handleChangeAutoComplete}
          required
          autoComplete="off"
        />
        { form.race!=="" &&
        <div className="d-flex mt-2 autocompleted_container" >
        {PET_RAZE_LIST.filter(item_raze=>{
            const searchTerm=form.race.toLowerCase()
            const raze_name=item_raze.toLowerCase()
            return searchTerm && raze_name.startsWith(searchTerm) && raze_name!==searchTerm
          }).slice(0,3).map((item_raze)=>(
            <h5 className="selectable pe-1" onClick={()=>selectAutoComplete('race',item_raze)} key={item_raze}><Badge bg="secondary">{item_raze}</Badge></h5>
          ))}
        </div>
        }
        {errors.race && (
          <p style={styles_error}>{errors.race}</p>
        )}
      </Form.Group>

        <Form.Group
          className="mb-3"
          controlId="exampleForm.ControlInput4"
        >
          <Form.Label>{TITLES_PET.sex}</Form.Label>
          <Form.Select
            name="sex"
            type="text"
            value={form.sex}
            onChange={handleChangeForm}
            required
            onBlur={handleBlur}
          >
          <option value="">-----------</option>
          <option value="MALE">{TITLES_PET_SEX['MALE']}</option>
          <option value="MALE_INCOMPLETE">{TITLES_PET_SEX['MALE_INCOMPLETE']}</option>
          <option value="FEMALE">{TITLES_PET_SEX['FEMALE']}</option>
          <option value="FEMALE_INCOMPLETE">{TITLES_PET_SEX['FEMALE_INCOMPLETE']}</option>
          </Form.Select>
          {errors.sex && <p style={styles_error}>{errors.sex}</p>}
        </Form.Group>

        <Form.Group
          className="mb-3"
          controlId="exampleForm.ControlTextarea3"
        >
          <Form.Label>{TITLES_PET.birthday}</Form.Label>
          <Form.Control
            name="date_of_birth"
            type="date"
            value={form.date_of_birth}
            onChange={handleChangeForm}
            required
            onBlur={handleBlur}
          />
          {errors.date_of_birth && (
            <p style={styles_error}>{errors.date_of_birth}</p>
          )}
        </Form.Group>

        <Form.Group
          className="mb-3"
          controlId="exampleForm.ControlInput4"
        >
          <Form.Label>{TITLES_PET.observations}</Form.Label>
          <Form.Control
            name="observations"
            type="text"
            as="textarea"
            rows={3}
            value={form.observations? form.observations:""}
            onChange={handleChangeForm}
            required
            onBlur={handleBlur}
          />
          {errors.observations && (
            <p style={styles_error}>{errors.observations}</p>
          )}
        </Form.Group>
        <Form.Group className='justify-content-end'  style={{display:'flex'}}>
        <Button variant="danger" type="submit">
          Guardar
        </Button>
        <Button variant="secondary" onClick={closeModal} className="ms-2">
          Cancelar
        </Button>
        </Form.Group>
    </Form>
    {
      openCrop &&
      <CropEasy photoUrl={photoUrl} setPhotoUrl={setPhotoUrl} setFile={setFile} show={openCrop} setOpenCrop={setOpenCrop}/>
    }
    </>
  )
}

let styles_error = {
  fontWeight: "bold",
  color: "#dc3545",
};

export default FormAdd