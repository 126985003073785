import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRouter from './router/AppRouter';
import { store } from './store/store';
import { Provider } from 'react-redux';


function App() {
  
  return (
    <Provider store={store}>
    <AppRouter/>
    </Provider>
  );
}

export default App;
