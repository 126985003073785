import React from "react";
import { Button, Form, Modal } from "react-bootstrap";


const VaccinateForm = ({ mostrarModal, cerrarModal, guardar, form, setForm, pets=[], veterinarians=[], read=false} ) => {

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit=async (e)=>{
    e.preventDefault()
    await guardar(form)
    cerrarModal()
  }

  const cancelSubmit=()=>{
    cerrarModal()
  }


  return (
    <Modal show={mostrarModal} onHide={cancelSubmit} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Crear Vacuna</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Mascota</Form.Label>
            <Form.Select
              name="pet"
              type="select"
              value={form.pet}
              onChange={handleInputChange} disabled={read}
              required
              //   onBlur={handleBlur}
            >
              <option key={-1} value={0}>--------------</option>
              {
                pets.map((item, key)=>(
                  <option key={key} value={item.pk}>{item.name} {item.pk}</option>
                ))
              }              
              </Form.Select>
            {/* {errors.name && <p style={styles_error}>{errors.name}</p>} */}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>Fecha de vacunación</Form.Label>
            <Form.Control
              name="date_revision"
              type="date"
              value={form.date_revision}
              onChange={handleInputChange} disabled={read}
              required
              //   onBlur={handleBlur}
            />
            {/* {errors.name && <p style={styles_error}>{errors.name}</p>} */}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              name="drug_name"
              type="text"
              value={form.drug_name}
              onChange={handleInputChange} disabled={read}
              required
              //   onBlur={handleBlur}
            />
            {/* {errors.name && <p style={styles_error}>{errors.name}</p>} */}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
            <Form.Label>Lote</Form.Label>
            <Form.Control
              name="drug_lote"
              type="text"
              value={form.drug_lote}
              onChange={handleInputChange} disabled={read}
              required
              //   onBlur={handleBlur}
            />
            {/* {errors.name && <p style={styles_error}>{errors.name}</p>} */}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
            <Form.Label>Compañia</Form.Label>
            <Form.Control
              name="drug_company"
              type="text"
              value={form.drug_company}
              onChange={handleInputChange} disabled={read}
              required
              //   onBlur={handleBlur}
            />
            {/* {errors.name && <p style={styles_error}>{errors.name}</p>} */}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
            <Form.Label>Proxima Fecha de vacunación</Form.Label>
            <Form.Control
              name="date_next_revision"
              type="date"
              value={form.date_next_revision}
              onChange={handleInputChange} disabled={read}
              required
              //   onBlur={handleBlur}
            />
            {/* {errors.name && <p style={styles_error}>{errors.name}</p>} */}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Profesional Autorizado</Form.Label>
            <Form.Select
              name="profile"
              type="select"
              value={form.profile}
              onChange={handleInputChange} disabled={read}
              required
              //   onBlur={handleBlur}
            >
              <option key={-1} value={0}>--------------</option>
              {
                veterinarians.map((item, key)=>(
                  <option key={key} value={item.pk}>{item.name} {item.pk}</option>
                ))
              }              
              </Form.Select>
            {/* {errors.name && <p style={styles_error}>{errors.name}</p>} */}
          </Form.Group>
          <Form.Group
            className="justify-content-end"
            style={{ display: "flex" }}
          >
            { !read && <Button variant="danger" type="submit">
              Guardar
            </Button>}
            <Button variant="secondary" onClick={cancelSubmit} className="ms-2">
              Cancelar
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default VaccinateForm;
