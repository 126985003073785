import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginScreen from "../screens/LoginScreen";
import SearchScreen from "../screens/SearchScreen";
import WorkingScreen from "../screens/WorkingScreen";
import NoScreen from "../screens/NoScreen";
import CustomersScreen from "../apps/customers/screen/CustomersScreen";
import PetScreen from "../apps/pets/screen/PetScreen";
import VeterinarianScreen from "../apps/veterinanians/screen/VeterinarianScreen";
import { useCheckAuth } from "../hooks/useCheckAuth";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { resetMessages } from "../store/slices/messageSlice";
import Swal from "sweetalert2";
import ProtectedRoutes from "./ProtectedRoutes";
import DetailScreen from "../screens/DetailScreen";
import PetScreenDetail from "../apps/pets/screen/PetScreenDetail";
import LoadingScreen from "../screens/LoadingScreen";
import VaccinationScreen from "../apps/drugs/screen/VaccinationScreen";
import DetailVaccinationScreen from "../apps/drugs/screen/DetailVaccinationScreen";

const AppRouter = () => {
  const { status, role } = useCheckAuth();

  const { messageError, messageSuccess } = useSelector(
    (state) => state.message
  );
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (messageError) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: `Error: ${messageError}!!`,
        showConfirmButton: false,
        timer: 3000,
      }).then((result) => {
        dispatch(resetMessages());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageError]);

  useEffect(() => {
    if (messageSuccess) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `Success: ${messageSuccess}!!`,
        showConfirmButton: false,
        timer: 3000,
      }).then((result) => {
        dispatch(resetMessages());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageSuccess]);

  if(status==='checking'){
    return <LoadingScreen/>
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SearchScreen />} />

        <Route element={<ProtectedRoutes is_allowed={(status==='authenticated' && role==='ADMIN')? true:false} redirectTo={"/login"}/>}>        
          <Route path="/veterinarios" element={<VeterinarianScreen />} />        
        </Route>

        <Route element={<ProtectedRoutes is_allowed={(status==='authenticated' && (role==='VETERINARIAN' || role==='ADMIN'  ))? true:false} redirectTo={"/login"}/>}>        
          <Route path="/clientes" element={<CustomersScreen />} />
          <Route path="/clientes/:idcustomer" element={<PetScreenDetail />} />
          <Route path="/mascotas" element={<PetScreen />} />        
          <Route path="/mascotas/:idpet/vacunas" element={<DetailVaccinationScreen />} />
          <Route path="/vacunas" element={<VaccinationScreen />} />
        </Route>
        
        <Route path="/historial" element={<WorkingScreen />} />
        <Route path="/control" element={<WorkingScreen />} />
        <Route path="/detalle/:idpet" element={<DetailScreen />} />
        {
          status==='authenticated'?
          <Route path="/login" element={<Navigate to="/" />} />:
          <Route path="/login" element={<LoginScreen />} />               
        }
        
        <Route path="/buscar" element={<SearchScreen />} />
        <Route path="/*" element={<NoScreen />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;

