import React, { useEffect, useState } from "react";
import { LayoutPublic } from "../components/layout/LayoutPublic";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AvatarPet from "../components/avatar_pet/AvatarPet";
import Banner from "../components/banner/Banner";
import NPetInfo from "../components/pet_info/NPetInfo";
import TitleBar from "../components/title_bar/TitleBar";
import NOwnerInfo from "../components/owner_info/NOwnerInfo";

import { useDispatch, useSelector } from "react-redux";
import BannerPublicity from "../components/banner_publicity/BannerPublicity";
import { useJsApiLoader } from "@react-google-maps/api";
import { mapOptions } from "../config/api_map";
import GMap from "../components/mapas_pet/gmap";

import PetMenu from "../components/pet_menu/petMenu";
import VaccinateTableRead from "../apps/drugs/components/VaccinateTableRead";

import PaginationPet from "../components/pagination/PaginationPet";
import { loadVaccinationsByPetThunks } from "../store/thunks/vaccionationThunks";
import { getDetailPetThunk } from "../store/petThunks";
import { useParams } from "react-router-dom";
import Loading from "../components/loading/Loading";


const DetailScreen = () => {
  const { idpet } = useParams();

  const dispatch = useDispatch();

  const getPet = async () => {
    dispatch(getDetailPetThunk(idpet));
    setLoading(false)
  };

  const { pet } = useSelector((state) => state.pet);
  const [loading, setLoading]= useState(true)

  const { vaccinations, current_page, total_pages } = useSelector(
    (state) => state.vaccination
  );

  const getVaccinations = async (pk, page) => {
    dispatch(loadVaccinationsByPetThunks(pk, page));
  };

  useEffect(() => {
    getPet();
     // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getVaccinations(idpet, 1);
     // eslint-disable-next-line
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: mapOptions.googleMapApiKey,
    googleMapsApiKey: mapOptions.googleMapApiKey,
  });

  const pageOne = () => (
    <>
      <Col md={12} className="mt-3">
        {pet && <NPetInfo pet={pet} />}
      </Col>

      <Col md={12} className="p-0 mt-2">
        {pet && <TitleBar title="Datos del propietario" />}
      </Col>

      <Col md={12} className="mt-3">
        {pet && <NOwnerInfo owner={pet.property_user} />}
      </Col>

      {pet && pet.property_user.latitude && (
        <Col
          md={12}
          className="mt-3 d-flex justify-content-center"
          style={{ height: "300px" }}
        >
          <GMap
            isLoaded={isLoaded}
            center={
              pet.property_user.latitude
                ? {
                    lat: parseFloat(pet.property_user.latitude),
                    lng: parseFloat(pet.property_user.longitude),
                  }
                : null
            }
          />
        </Col>
      )}
    </>
  );

  const pageTwo = () => (
    <>
      <Col md={12} className="mt-3">
        {vaccinations && (
          <Col md={12} className="mt-3">
            <VaccinateTableRead vaccionations={vaccinations} current_page={1} />
            {vaccinations.length > 0 ? (
              <PaginationPet
                activarPage={getVaccinations}
                current_page={current_page}
                total_pages={total_pages}
              />
            ) : (
              <h2> No existen vacunas</h2>
            )}
          </Col>
        )}
      </Col>
    </>
  );
 

  const titlestabs = {
    1: "Perfil",
    2: "Vacunas",
    3: "Control de Parasitos",
    4: "Historial Clinico",
  };

  const titles_tabs = [
    {
      id: 1,
      name: titlestabs[1],
      page: pageOne(),
    },
    {
      id: 2,
      name: titlestabs[2],
      page: pageTwo(),
    },
    // {
    //   id: 3,
    //   name: titlestabs[3],
    //   page: pageThree(),
    // },
    // {
    //   id: 4,
    //   name: titlestabs[4],
    //   page: pageFour(),
    // },
  ];

  const [activeTab, setActiveTab] = useState(1);

  const handleTab = (id) => {
    setActiveTab(id);
  };

  return (
    <>
      <LayoutPublic>
        <Container className="pb-3">
          {loading && (
            <Row className="mt-5">
              <Col>
                <Loading />
              </Col>
            </Row>
          )}
          {!loading && (
            <>
              <PetMenu
                titles_tabs={titles_tabs}
                handleTab={handleTab}
                activeTab={activeTab}
              />
              <Row>
                <Col md={12} className="mt-3">
                  <Banner />
                </Col>
                <Col md={12} className="mt-3">
                  {pet && (
                    <AvatarPet
                      pet_name={pet.name}
                      pet_status={pet.status}
                      pet_image={pet.reference}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                {activeTab === titles_tabs[0].id && titles_tabs[0].page}
                {activeTab === titles_tabs[1].id && titles_tabs[1].page}
                {/* a  {activeTab === titles_tabs[2].id && titles_tabs[2].page}
            {ctiveTab === titles_tabs[3].id && titles_tabs[3].page} */}
              </Row>

              <Row>
                <Col md={12} className="p-0 mt-3">
                  <BannerPublicity />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </LayoutPublic>
    </>
  );
};

export default DetailScreen;
