import React, { useState } from "react";
import { LayoutPublic } from "../components/layout/LayoutPublic";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "../hooks/useForm";
import { validations_form_login } from "../helpers/validations";
import InputGroup from "react-bootstrap/InputGroup";
import { useDispatch } from "react-redux";
import { startAuthentication } from "../store/thunks";

const initial_values = {
  email: "",
  password: "",
};

const LoginScreen = () => {
  const { form, errors, handleBlur, handleChangeForm, setErrors } = useForm(
    initial_values,
    validations_form_login
  );

  const [showPassword, setShowPassword] = useState(false);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const login = async (e) => {
    e.preventDefault();

    const formErrors = validations_form_login(form);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    dispatch(startAuthentication(form.email, form.password));
    navigate("/");
  };

  return (
    <LayoutPublic>
      <Container>
        <Row>
          <Col>{/* <Banner /> */}</Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs="12" md="6">
            <h4 className="text-center m-4">LOGIN</h4>
            <Form onSubmit={login}>
              <Form.Group className="mb-3" controlId="formBasicemail">
                <Form.Label>email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter email"
                  name="email"
                  value={form.email}
                  onChange={handleChangeForm}
                  onBlur={handleBlur}
                />
                {errors.email && (
                  <p className="message-error">{errors.email}</p>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Contraseña</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={form.password}
                    onChange={handleChangeForm}
                    onBlur={handleBlur}
                  />
                  <Button
                    variant="outline-secondary"
                    id="button-addon2"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!showPassword ? (
                      <i className="fas fa-eye"></i>
                    ) : (
                      <i className="fas fa-eye-slash"></i>
                    )}
                  </Button>
                </InputGroup>
                {errors.password && (
                  <p className="message-error">{errors.password}</p>
                )}
              </Form.Group>

              <Button variant="danger" type="submit">
                Iniciar Sesion
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </LayoutPublic>
  );
};

export default LoginScreen;
