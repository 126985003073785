import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  customers: [],
  current_page:0,
  value:0,
  isEditing:false, 
  total_items:10,
  total_pages:1
}

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    loadCustomers:(state,{payload})=>{
      state.customers=payload.customers
      state.current_page=payload.current_page
      state.total_items=payload.total_items
      state.total_pages=payload.total_pages
    },
    setCustomer:(state,{payload})=>{
      state.customers.push(payload)
    },
    updateCustomer:(state,{payload})=>{
      state.customers=state.customers.map(item=>{
        if (item.pk===payload.pk){
          return payload
        }
        return item
      })
      state.isEditing=false
    },
    deleteCustomer:(state,{payload})=>{
      state.customers=state.customers.filter(item=>item.pk!==payload)
      state.isEditing=false
    },
    setEditing:(state,{payload})=>{
      state.isEditing=true
    },
    notEditing:(state,{payload})=>{
      state.isEditing=false
    }
  },
})

// Action creators are generated for each case reducer function
export const {notEditing, setEditing, updateCustomer, deleteCustomer, loadCustomers, setCustomer } = customerSlice.actions