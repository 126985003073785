import React from "react";
import { Table } from "react-bootstrap";
import './VaccinateTableRead.css'

const VACCINATE_TITLES = {
  drug_name: "Nombre de la Vacuna",
  drug_brand: "Fabricante",
  drug_lote: "N° Lote",
  drug_company: "Fabricante",
  date_revision: "Fecha de vacunación",
  date_next_revision: "Próxima Fecha de vacunación",
  profile: "Profesional Autorizado",
  pet: "Mascota",
};

const VACCINATE_NROS = {
  nro_one: "¹",
  nro_two: "²",
  nro_three: "³",
};

const VaccinateTableRead = ({ current_page, vaccionations }) => {
    

  return (
    <Table responsive="sm" bordered hover>
      <thead className="align-middle" variant="danger">
        <tr>
          <th className="col-1" style={{backgroundg:'green', width:'80px',minWidth:'80px'}}>#</th>
          <th style={{backgroundg:'green', width:'150px',minWidth:'150px'}}>
            <div>
              <p>
                {VACCINATE_TITLES.date_revision} {VACCINATE_NROS.nro_one}
              </p>
              <p>
                {VACCINATE_TITLES.date_next_revision}
                {VACCINATE_NROS.nro_two}
              </p>
            </div>
          </th>
          <th className="col-3" style={{backgroundg:'green', width:'200px',minWidth:'200px'}}>
            <div>
              <p>
                {VACCINATE_NROS.nro_one} {VACCINATE_TITLES.drug_name}
              </p>
              <p>
                {VACCINATE_NROS.nro_two} {VACCINATE_TITLES.drug_company}
                {"  "}
                {VACCINATE_NROS.nro_three} {VACCINATE_TITLES.drug_lote}
              </p>
            </div>
          </th>

          <th className="col-4" style={{backgroundg:'green'}}>
            <div>
              <p>{VACCINATE_TITLES.profile}</p>
            </div>
          </th>
         
        </tr>
      </thead>
      <tbody className="align-middle">
        {vaccionations.map((item, key) => (
          <tr key={key}>
            <td className="col-1">{(key+1)+((current_page-1)*10)}</td>
            <td className="col-2">
              <div>
                <p>
                  {item.date_revision && VACCINATE_NROS.nro_one}
                  {item.date_revision}
                </p>
                {item.date_next_revision && (
                  <p>
                    {VACCINATE_NROS.nro_two}
                    {item.date_next_revision}
                  </p>
                )}
              </div>
            </td>
            <td className="col-3">
              <div>
                <p> <strong>{`${VACCINATE_NROS.nro_one} ${item.drug_name}`}</strong></p>
                <p>
                  {item.drug_company && VACCINATE_NROS.nro_two}
                  {item.drug_company}{" "}
                  {item.drug_lote && VACCINATE_NROS.nro_three}
                  {item.drug_lote && `Lote: ${item.drug_lote}`}{" "}
                </p>{" "}
              </div>
            </td>
            
            <td className="col-4">
              <div className="col-4">
                <p>
                  {item.profile.name}
                </p>
                <p>{item.profile.enrollment && `Matricula Prof. ${item.profile.enrollment}`}</p>
              </div>

            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default VaccinateTableRead;
