import React, { useState } from "react";
import { Button, Image, Modal, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import avatar_pet from "../../../images/avatar_pet.png";
import { pet_delete_api } from "../api/pets_api";
import FormAdd from "../forms/FormAdd";
import {
  TITLES_PET,
  TITLES_PET_LOST,
  TITLES_PET_SEX,
} from "../../../helpers/titles_constants";
import { deletePetThunk, updatePetThunk } from "../../../store/petThunks";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const form_default = {
  pk: "",
  date_of_birth: "",
  name: "",
  number_chip: "",
  observations: "",
  race: "",
  sex: "",
  species: "",
  status: "",
  tattoo: "",
  reference: null,
  property_user: "",
};

const TITLE = "Mascotas";

const TablePets = ({ pets = [] }) => {
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [form, setForm] = useState(form_default);

  const selectItemEdit = (item) => {
    setForm(item)
    setModalEdit(true);
  };

  const selectItemDelete = (item) => {
    setForm(item);
    setModalDelete(true);
  };

  const deletePet = async () => {
    await pet_delete_api(form.pk);
    dispatch(deletePetThunk(form.pk))
    setModalDelete(false);
  };

  const user =useSelector(state=>state.auth)
  // const user = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();

  const selectItemLost = async (item) => {
    let status = "LOST";

    if (item.status === "LOST") {
      status = "NOTLOST";
    }

    Swal.fire({
      title: "Se perdio la Mascota!",
      text: "Estas seguro de realizar esta accion?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, crealo!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(
          updatePetThunk(item.pk,{
            pk: item.pk,
            profile: item.profile.pk,
            property_user: item.property_user.pk,
            status: status,
          })
        );
      }
    });
  };

  return (
    <>
      <Table responsive="sm" striped bordered hover className="mt-2">
        <thead>
          <tr>
            <th>#</th>
            <th style={{ minWidth: 98 }}>Image</th>
            <th>{TITLES_PET.name}</th>
            <th>{TITLES_PET.status}</th>
            <th>{TITLES_PET.nro_chip}</th>
            <th>{TITLES_PET.tattoo}</th>
            <th>{TITLES_PET.speci}</th>
            <th>{TITLES_PET.raze}</th>
            <th>{TITLES_PET.sex}</th>
            {user && <th>Acciones</th>}
          </tr>
        </thead>
        <tbody>
          {pets.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.pk}</td>
                <td style={{ maxWidth:80}}>
                  <Image
                    src={item.reference ? item.reference : avatar_pet}
                    style={{
                      borderRadius:'50%',
                      minHeight: 50,
                      maxHeight: 80,
                      height: 80,
                      width: 80,
                      minWidth: 50,
                      maxWidth: 80,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </td>
                <td>{item.name}</td>
                <td>{TITLES_PET_LOST[item.status]}</td>
                <td>{item.number_chip ? item.number_chip : "-------"}</td>
                <td>{item.tattoo}</td>
                <td>{item.species}</td>
                <td>{item.race}</td>
                <td>{TITLES_PET_SEX[item.sex]}</td>

                {user.role && user.role === "ADMIN" && (
                  <td>
                    <>
                      <Button
                        variant="danger"
                        onClick={() => selectItemEdit(item)}
                        className="me-2 mb-2"
                      >
                        <i className="fas fa-edit"></i>
                      </Button>

                      <Button
                        className="me-2 mb-2"
                        variant="danger"
                        onClick={() => selectItemDelete(item)}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </Button>

                      <Button
                        className="me-2 mb-2"
                        variant="danger"
                        onClick={() => selectItemLost(item)}
                      >
                        <i
                          className={
                            item.status === "LOST"
                              ? "fas fa-eye-slash"
                              : "fas fa-eye"
                          }
                        ></i>
                      </Button>
                      
                      <Button
                        className="me-2 mb-2"
                        variant="danger"                        
                      >
                      <NavLink
                        to={"/mascotas/"+item.pk+"/vacunas/"}
                        style={{color:"#fff"}}
                      >
                        <i className="fas fa-syringe"></i>
                      </NavLink> 
                      </Button>                   
                      
                      
                    </>
                  </td>
                )}

              {user.role && user.role === "VETERINARIAN" && (
                  <td>
                    <>
                      
                      <Button
                        className="me-2 mb-2"
                        variant="danger"                        
                      >
                      <NavLink
                        to={"/mascotas/"+item.pk+"/vacunas/"}
                        style={{color:"#fff"}}
                      >
                        <i className="fas fa-syringe"></i>
                      </NavLink> 
                      </Button>                   
                      
                      
                    </>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Modal
        show={modalDelete}
        onHide={() => setModalDelete(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Eliminar ${TITLE}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Woohoo, Esta seguro que desea eliminar a <strong>{form.name}</strong>!
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setModalDelete(false);
            }}
          >
            Cancelar
          </Button>
          <Button variant="danger" onClick={deletePet}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalEdit}
        onHide={() => setModalEdit(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Editar ${TITLE}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormAdd form_edit={form} closeModal={() => setModalEdit(false)} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default TablePets;
