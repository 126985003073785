import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { petFindThunk } from "../../../store/petThunks";

const FormFind = () => {
  const [values, setValues] = useState({ name: "" });

  const handleInput = (e) => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
  };
  
  const dispatch=useDispatch()

   const buscar = async(e)=>{
    e.preventDefault()
    dispatch(petFindThunk(values.name))
  }

  return (
    <Form onSubmit={(e)=>buscar(e)}>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Introdusca el nombre."
          aria-label="Introdusca el nombre."
          aria-describedby="basic-addon2"
          type="text"
          name="name"
          value={values.name}
          onChange={handleInput}
        />
        <Button variant="danger" id="button-addon2" type="submit">
          Buscar
        </Button>
      </InputGroup>
    </Form>
  );
};

export default FormFind;
