import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./menu.css";
import { NavLink} from "react-router-dom";

import { COLORS } from "../../helpers/colors";
import { Button } from "react-bootstrap";

const Menu = ({ user, status, path_name, role, logout }) => {
  
  return (
    <Container>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        {status==='authenticated' && user && (
          <>
            <Nav className="me-auto">
            <NavLink
                to={"/buscar"}
                className="linkp d-flex"
                style={({ isActive }) => ({
                  color: isActive ? COLORS.red : COLORS.light_gray,
                })}
              >
                Buscar
              </NavLink>
              {role==='ADMIN' && <NavLink
                to={"/veterinarios"}
                className="linkp d-flex"
                style={({ isActive }) => ({
                  color: isActive ? COLORS.red : COLORS.light_gray,
                })}
              >
                Veterinarios
              </NavLink>}
              <NavLink
                to={"/clientes"}
                className="linkp d-flex"
                style={({ isActive }) => ({
                  color: isActive ? COLORS.red : COLORS.light_gray,
                })}
              >
                Clientes
              </NavLink>
              <NavLink
                to={"/mascotas"}
                className="linkp d-flex"
                style={({ isActive }) => ({
                  color: isActive ? COLORS.red : COLORS.light_gray,
                })}
              >
                Mascotas
              </NavLink>
              <NavLink
                to={"/vacunas"}
                className="linkp d-flex"
                style={({ isActive }) => ({
                  color: isActive ? COLORS.red : COLORS.light_gray,
                })}
              >
                Vacunas
              </NavLink>
            </Nav>

            <Navbar.Text className="pe-2">
              Usuario: <span style={{color: COLORS.blue}}>{user.email}</span>
            </Navbar.Text>
            <Nav>
              <Button

                onClick={logout}
                className="btn btn-danger"
                style={{ color: "white", paddingInline: 15 }}
              >
                Salir
              </Button>
            </Nav>
          </>
        )}
        {status === "not-authenticated" && (
          <>
            <NavLink
              to={"/buscar"}
              className="linkp d-flex"
              style={({ isActive }) => ({
                color: isActive ? COLORS.red : COLORS.light_gray,
              })}
            >
              Buscar
            </NavLink>
            <NavLink
              to={"/login"}
              className="linkp d-flex"
              style={({ isActive }) => ({
                color: isActive ? COLORS.red : COLORS.light_gray,
              })}
            >
              Login
            </NavLink>
          </>
        )}
      </Navbar.Collapse>
    </Container>
  );
};

export default Menu;
