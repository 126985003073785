import React from 'react'
import NavbarPet from'../navbar_pet/NavbarPet'
export const LayoutPublic = ({children}) => {
  return (
    <div>
        <NavbarPet/>
        <div>
          {children}
        </div>
    </div>
  )
}
