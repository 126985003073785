import React, { useState } from "react";
import { LayoutPublic } from "../components/layout/LayoutPublic";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Banner from "../components/banner/Banner";
import { Button, Card, Form, Image, InputGroup } from "react-bootstrap";
import { search_api } from "../api/search_api";
import avatar_pet from "../images/avatar_pet.png";

import { COLORS } from "../helpers/colors";
import { TITLES_PET } from "../helpers/titles_constants";
import Loading from "../components/loading/Loading";

const value_initial = {
  number_chip: "",
};

const SearchScreen = () => {
  const [values, setValues] = useState(value_initial);
  const [pets, setPets] = useState([]);
  const [petView, setPetView] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading]=useState(false)

  const buscar = async (e) => {
    setLoading(true)
    setNotFound(false);
    setPets([]);
    e.preventDefault();
    if (values.number_chip.length > 0) {
      try {
        const res = await search_api(values.number_chip.replaceAll(" ", ""));
        setPets(res);
        setPetView(null);
        if (res.results.length === 0) {
          setNotFound(true);
        }  
      } catch (error) {
        console.log("error de busqueda", error)
      }
      setLoading(false)
    }
  };

  const handleInput = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  
  return (
    <>
      <LayoutPublic fluid>
        <Container className="mb-3">
          <Row>
            <Col md={12}  className="mb-4">
              <Banner />
            </Col>
            <Col md={12}>
              <Form onSubmit={buscar}>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Introduzca el número de chip o tatuaje"
                    aria-label="Introduzca el número de chip o tatuaje"
                    aria-describedby="basic-addon2"
                    type="text"
                    name="number_chip"
                    value={values.number_chip}
                    onChange={handleInput}
                    style={{ fontSize: "0.8rem" }}
                  />
                  <Button variant="danger" id="button-addon2" type="submit">
                    Buscar
                  </Button>
                </InputGroup>
              </Form>
            </Col>
          </Row>
          {!petView && pets.results && pets.results.length > 0 && (
            <Row xs={1} md={4} className="g-4">
              {pets.results.map((item, idx) => (
                <Col key={idx}>
                  <Card>
                    <Card.Body className="text-center">
                      <Image
                        style={{
                          height: 150,
                          width:150,
                          objectFit: "cover",
                          objectPosition: "center",
                          borderRadius: "50%",
                        }}
                        variant="top"
                        src={item.reference ? item.reference : avatar_pet}
                      />
                    </Card.Body>
                    <Card.Body>
                      <Row>
                        <Col className="d-flex" md={12}>
                          <Col md={5}>
                            <Card.Title
                              className="p-1 me-2"
                              style={{
                                fontSize: "0.8rem",
                                background: COLORS.gray,
                                height: "30px",
                                color: COLORS.white,
                              }}
                            >
                              {TITLES_PET.name}:
                            </Card.Title>
                          </Col>
                          <Col>
                            <Card.Title
                              className="p-1"
                              style={{
                                fontSize: "0.8rem",
                                background: COLORS.light_blue,
                                height: "30px",
                              }}
                            >
                              {item.name}
                            </Card.Title>
                          </Col>
                        </Col>

                        <Col className="d-flex" md={12}>
                          <Col md={5}>
                            <Card.Title
                              className="p-1 me-2"
                              style={{
                                fontSize: "0.8rem",
                                background: COLORS.gray,
                                height: "30px",
                                color: COLORS.white,
                              }}
                            >
                              {TITLES_PET.nro_chip}:
                            </Card.Title>
                          </Col>
                          <Col>
                            <Card.Title
                              className="p-1"
                              style={{
                                fontSize: "0.8rem",
                                background: COLORS.light_blue,
                                height: "30px",
                              }}
                            >
                              {item.number_chip}
                            </Card.Title>
                          </Col>
                        </Col>

                        <Col className="d-flex" md={12}>
                          <Col  md={5}>
                            <Card.Title
                              className="p-1 me-2"
                              style={{
                                fontSize: "0.8rem",
                                background: COLORS.gray,
                                height: "30px",
                                color: COLORS.white,
                              }}
                            >
                              {TITLES_PET.tattoo}:
                            </Card.Title>
                          </Col>
                          <Col>
                            <Card.Title
                              className="p-1"
                              style={{
                                fontSize: "0.8rem",
                                background: COLORS.light_blue,
                                height: "30px",
                              }}
                            >
                              {item.tattoo ? item.tattoo : ""}
                            </Card.Title>
                          </Col>
                        </Col>
                        <Col md={12}>
                          <Button
                            href={`/detalle/${item.pk}`}
                            style={{ width: "100%" }}
                            variant="danger"                            
                          >
                             Ver mas...
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
          {loading && (
            <Row className="mt-5">
              <Col>
                <Loading/>
              </Col>
            </Row>
          )}
          {notFound && (
            <Row>
              <Col>
                <h2>No se encontraron coincidencias!</h2>
              </Col>
            </Row>
          )}
        </Container>
      </LayoutPublic>
    </>
  );
};

export default SearchScreen;
