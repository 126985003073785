import axiosAPI from "../../../api/axiosConfig"

const API_VACCINATION="drug/vaccination"

export const vaccinations_api = async( page=1, page_size=10)=>{ 
    const res = await axiosAPI.get(`/${API_VACCINATION}/?page=${page}`)    
    return res.data
}

export const vaccination_post_api = async(data)=>{
    try{
        const res=await axiosAPI.post(`/${API_VACCINATION}/vaccinate/create/`,data)
        return res
    }catch(err){
        return false
    }       
}

export const vaccination_patch_api = async(pk, data)=>{
    try{
        const res=await axiosAPI.patch(`/${API_VACCINATION}/vaccinate/${pk}/update/`,data)
        return res
    }catch(err){
        return false
    }       
}

export const vaccination_delete_api = async(pk)=>{
    try{
        const res=await axiosAPI.delete(`/${API_VACCINATION}/vaccinate/${pk}/delete/`)
        return res
    }catch(err){
        return false
    }       
}

export const vaccinations_by_pet_api = async( pk, page)=>{ 
    try {
        const res = await axiosAPI.get(`/${API_VACCINATION}/pet/${pk}/?page=${page}`)        
        return res.data
    } catch (error) {
        return {results:[]}
    }
    
    
    
    
}



