import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { LayoutPublic } from "../../../components/layout/LayoutPublic";
import VaccinateTable from "../components/VaccinateTable";
import VaccinateForm from "../components/VaccinateForm";
import { useParams } from "react-router-dom";
import Banner from "../../../components/banner/Banner";
import AvatarPet from "../../../components/avatar_pet/AvatarPet";
import PaginationPet from "../../../components/pagination/PaginationPet";
import { useDispatch, useSelector } from "react-redux";
import {
  createVaccinationByPetThunk,
  deleteVaccinationByPetThunk,
  loadVaccinationsByPetThunks,
  updateVaccinationByPetThunk,
} from "../../../store/thunks/vaccionationThunks";
import {
  loadPetsThunks,
  loadVeterinariansThunks,
} from "../../../store/thunks/listThunks";
import { getDetailPetThunk } from "../../../store/petThunks";

const initial_vaccinate = {
  drug_name: "",
  drug_brand: "",
  drug_lote: "",
  drug_company: "",
  date_revision: new Date().toISOString().substr(0, 10),
  date_next_revision: "",
  profile: "",
  pet: "",
};
// const TITLE = "Vacunas";

const DetailVaccinationScreen = () => {
  const { idpet } = useParams();

  // const [pets, setPets] = useState([]);
  const [form, setForm] = useState({ ...initial_vaccinate });
  const [mostrarModal, setMostrarModal] = useState(false);
  const [read, setRead] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const user = useSelector((state) => state.auth);

  const vaccination = useSelector((state) => state.vaccination);
  const { vaccinations, current_page, total_pages } = vaccination;

  const { pet } = useSelector((state) => state.pet);

  const list = useSelector((state) => state.list);
  const { pets, veterinarians } = list;

  const cerrarModal = () => setMostrarModal(false);
  
  const getVeterinarians = async () => {
    dispatch(loadVeterinariansThunks());
  };

  const getPets = async () => {
    dispatch(loadPetsThunks());
  };

  const getDetailPet = async () => {
    dispatch(getDetailPetThunk(idpet));
    setForm({ ...form, pet: idpet });
  };

  useEffect(() => {
    getDetailPet();
    // eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();

  const getVaccinations = async (page = 1) => {
    dispatch(loadVaccinationsByPetThunks(idpet, page));
  };

  const guardar = async (form) => {
    if (isSaving) return;

    if (!form.hasOwnProperty("id")) {
      setIsSaving(true);
      dispatch(createVaccinationByPetThunk(idpet, form));
      setIsSaving(false);
      return;
    }

    setIsSaving(true);
    const form_res = { ...form };
    delete form_res.id;
    dispatch(updateVaccinationByPetThunk(form.id, form_res, idpet));
    setIsSaving(false);
  };

  const deleteItem = async (item) => {
    dispatch(deleteVaccinationByPetThunk(item.id, idpet));
  };

  const selectItem = (item) => {
    setRead(false);
    setForm({ ...item, pet: item.pet.id, profile: item.profile.pk });
    setMostrarModal(true);
  };

  useEffect(() => {
    getVaccinations();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPets();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getVeterinarians();
    // eslint-disable-next-line
  }, []);

  const handleNew = () => {
    setRead(false);
    setForm({ ...initial_vaccinate, profile: user.pk, pet: idpet });
    setMostrarModal(true);
  };

  const readItem = (item) => {
    setForm({ ...item, pet: item.pet.id, profile: item.profile.pk });
    setRead(true);
    setMostrarModal(true);
  };
  return (
    <LayoutPublic>
      <Container className="pb-3">
        <Row>
          <Col md={12} className="mt-3">
            <Banner />
          </Col>
          <Col md={12} className="mt-3">
            {pet && (
              <AvatarPet
                pet_name={pet.name}
                pet_status={pet.status}
                pet_image={pet.reference}
              />
            )}
          </Col>
          <Col md={12} className="mt-3">
            <Button variant="danger" className="mb-3" onClick={handleNew}>
              {" "}
              Agregar
              <i className="fas fa-plus-circle mx-2"></i>
            </Button>
          </Col>
          {vaccinations && (
            <Col md={12} className="mt-3">
              <VaccinateTable
                vaccionations={vaccinations}
                selectItem={selectItem}
                deleteItem={deleteItem}
                readItem={readItem}
                current_page={current_page}
              />
              {vaccinations.length > 0 ? (
                <PaginationPet
                  activarPage={getVaccinations}
                  current_page={current_page}
                  total_pages={total_pages}
                />
              ) : (
                <h2> No existen vacunas</h2>
              )}
            </Col>
          )}
        </Row>

        <VaccinateForm
          mostrarModal={mostrarModal}
          cerrarModal={cerrarModal}
          guardar={guardar}
          form={form}
          setForm={setForm}
          pets={pets}
          veterinarians={veterinarians}
          read={read}
        />
      </Container>
    </LayoutPublic>
  );
};

export default DetailVaccinationScreen;
