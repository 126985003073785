import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { LayoutPublic } from "../../../components/layout/LayoutPublic";
import VaccinateTable from "../components/VaccinateTable";
import VaccinateForm from "../components/VaccinateForm";
import PaginationPet from "../../../components/pagination/PaginationPet";

import { useDispatch, useSelector } from "react-redux";
import {
  createVaccinationThunk,
  deleteVaccinationThunk,
  loadVaccionationThunks,
  updateVaccinationThunk,
} from "../../../store/thunks/vaccionationThunks";
import {
  loadPetsThunks,
  loadVeterinariansThunks,
} from "../../../store/thunks/listThunks";

const initial_vaccinate = {
  drug_name: "",
  drug_brand: "",
  drug_lote: "",
  drug_company: "",
  date_revision: new Date().toISOString().substr(0, 10),
  date_next_revision: "",
  profile: "",
  pet: "",
};
const TITLE = "Vacunas";

const VaccinationScreen = () => {
  const [form, setForm] = useState(initial_vaccinate);
  const [mostrarModal, setMostrarModal] = useState(false);
  const [read, setRead] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const list = useSelector((state) => state.list);
  const { pets, veterinarians } = list;

  const vaccination = useSelector((state) => state.vaccination);
  const { current_page, total_items, total_pages, vaccinations } = vaccination;

  const cerrarModal = () => setMostrarModal(false);

  const getVeterinarians = async () => {
    dispatch(loadVeterinariansThunks());
  };

  const getPets = async () => {
    dispatch(loadPetsThunks());
  };

  const getVaccination = (page = 1) => {
    dispatch(loadVaccionationThunks(page, 10));
  };

  const guardar = async (form) => {
    if (isSaving)  return;

    if (!form.hasOwnProperty("id")) {
      setIsSaving(true)
      dispatch(createVaccinationThunk(form));
      setIsSaving(false)
      return;
    }
    
    setIsSaving(true)
    const form_res = { ...form };
    delete form_res.id;
    dispatch(updateVaccinationThunk(form.id, form_res));
    setIsSaving(false)
  };

  const deleteItem = async (item) => {
    dispatch(deleteVaccinationThunk(item.id));
  };

  const selectItem = (item) => {
    setRead(false);
    setForm({ ...item, pet: item.pet.id, profile: item.profile.pk });
    setMostrarModal(true);
  };

  useEffect(() => {
    getVaccination();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPets();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getVeterinarians();
    // eslint-disable-next-line
  }, []);

  const handleNew = () => {
    setForm({ ...initial_vaccinate, profile: user.pk });
    setMostrarModal(true);
  };

  const readItem = (item) => {
    setForm({ ...item, pet: item.pet.id, profile: item.profile.pk });
    setRead(true);
    setMostrarModal(true);
  };

  return (
    <LayoutPublic>
      <Container className="pb-3">
        <h2>{TITLE}</h2>
        <Row>
          <Col md={12} className="mt-3">
            <Button variant="danger" className="mb-3" onClick={handleNew}>
              {" "}
              Agregar {TITLE}
              <i className="fas fa-plus-circle mx-2"></i>
            </Button>
          </Col>
          <Col md={12} className="mt-3">
            <VaccinateTable
              vaccionations={vaccinations}
              selectItem={selectItem}
              deleteItem={deleteItem}
              readItem={readItem}
              current_page={current_page}
            />
            {vaccinations.length > 0 ? (
              <PaginationPet
                activarPage={getVaccination}
                total_items={total_items}
                current_page={current_page}
                total_pages={total_pages}
              />
            ) : (
              <h2> No existen vacunas</h2>
            )}
          </Col>
          <Col></Col>
        </Row>

        <VaccinateForm
          mostrarModal={mostrarModal}
          cerrarModal={cerrarModal}
          guardar={guardar}
          form={form}
          setForm={setForm}
          pets={pets}
          veterinarians={veterinarians}
          read={read}
        />
      </Container>
    </LayoutPublic>
  );
};

export default VaccinationScreen;
