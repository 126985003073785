import axiosAPI from "./axiosConfig"

const API_PETS="mascot/pets"

export const pets_list=async ()=>{    
    try{
        const res= await axiosAPI.get(`${API_PETS}/list/`)
        return res    
    }catch(err){
        return null
    }  
}

const API_PROFILES="/accounts/profiles"

export const profiles_list=async ()=>{    
    try{
        const res= await axiosAPI.get(`${API_PROFILES}/list/`)
        return res    
    }catch(err){
        return null
    }  
}