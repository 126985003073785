import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { customerFindThunk } from "../../../store/customerThunks";

const FormFind = () => {
  const dispatch=useDispatch()
  const [values, setValues] = useState({ name: "" });

  const handleInput = (e) => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
  };

  const buscar=(e, name)=>{
    e.preventDefault()
    dispatch(customerFindThunk(name))    
  }

  return (
    <Form onSubmit={(e)=>buscar(e, values.name)}>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Introdusca el nombre."
          aria-label="Introdusca el nombre."
          aria-describedby="basic-addon2"
          type="text"
          name="name"
          value={values.name}
          onChange={handleInput}
        />
        <Button variant="danger" id="button-addon2" type="submit">
          Buscar
        </Button>
      </InputGroup>
    </Form>
  );
};

export default FormFind;
