import { setEditing,loadPets,deletePet, updatePet, setPet, setCustomer, selectPet} from "./slices/petSlice";
import { pets_api, pet_delete_api, pet_find_api, pet_post_api, pet_put_api, pets_customer_api, customer_get_api, pet_get_api } from '../apps/pets/api/pets_api';
import { setMessage } from "./slices/messageSlice";


export const petFindThunk =  (name) => {
  return async (dispatch)=>{
      const res = await pet_find_api(name)
      dispatch(loadPets({pets:res.results, current_page:res.current_page, total_items:res.count , total_pages:res.total_pages }))
  }    
};

export const loadPetsThunk =  (page = 1, page_size = 10) => {
    return async (dispatch)=>{
        const res = await pets_api(page,page_size);
        dispatch(loadPets({pets:res.results, current_page:res.current_page, total_items:res.count , total_pages:res.total_pages }))
    }    
  };


  export const loadPetsCustomerThunk =  (pk, limit = 10, offset = 0 ) => {
    return async (dispatch)=>{
        try {
          const res = await pets_customer_api(pk);   
          dispatch(loadPets({pets:res.results, current_page:res.current_page, total_items:res.count , total_pages:res.total_pages }))  
        } catch (error) {
          dispatch(loadPets({pets:[], currentPage:offset+1, totalItems:0 }))
        }
        
    }    
  };

  export const loadCustomerThunk =  (pk) => {
    return async (dispatch)=>{
        const res = await customer_get_api(pk);
        dispatch(setCustomer(res))
    }    
  };


  export const createPetThunk =  (form) => {
    return async (dispatch)=>{
      dispatch(setEditing())
      try{  
        const res = await pet_post_api(form);        
        const pet=res.data
        dispatch(setPet({...pet}))
        }catch(e){
          dispatch(setMessage({message:"errror edit",typeMessage:'error'}))
        }
    }    
  };

  export const updatePetThunk =  (pk, pet) => {
    return async (dispatch)=>{
        dispatch(setEditing())
        try{
          const res = await pet_put_api(pk, pet);    
          if(res){
            dispatch(updatePet({...res.data}))
            dispatch(setMessage({message:"Mascota se  correctamente!!",typeMessage:'success'})) 
            return
          }
        }catch(e){
          dispatch(setMessage({message:"Mascota no pudo ser editado",typeMessage:'error'}))          
        }
        
    }    
  };

  export const deletePetThunk = (pk) => {
    return async (dispatch)=>{
        dispatch(setEditing())
        
        const res=await pet_delete_api(pk)
        dispatch(deletePet(pk))
        if (res){
          dispatch(setMessage({message:"Mascota se elimino correctamente!!",typeMessage:'success'}))
        }else{
          dispatch(setMessage({message:"Mascota no pudo ser eliminado!!",typeMessage:'error'}))
        }
    }    
  };

  

  export const getDetailPetThunk = (idpet) => {
    return async (dispatch)=>{
      dispatch(setEditing())
      const res = await pet_get_api(idpet);
      dispatch(selectPet(res))
    }
  };