
export const validations_form_customers = (form) => {
  let error = {};
  if (!form.name.trim()) {
    error.name = "El campo nombre es requerido!";
  }

  if (!form.identification_card.trim()) {
    error.identification_card = "El campo cedula es requerido!";
  }

  if (!form.location.trim()) {
    error.location = "El campo ubicacion es requerido!";
  }

  if (form.other_phone[0]==="") {
    error.other_phone = "El campo phone es requerido!";
  }

  return error;
};

export const validations_form_pets = (form) => {
  let error = {};
  if (!form.name.trim()) {
    error.name = "El campo nombre es requerido!";
  }

  if (!form.race.trim()) {
    error.race = "El campo raza es requerido!";
  }

  if (!form.date_of_birth.trim()) {
    error.date_of_birth = "El campo fecha de nacimiento es requerido!";
  }else{
    let hoy             = new Date();
    hoy.setHours(0,0,0,0);  // Lo iniciamos a 00:00 horas
    let fechaFormulario = new Date(form.date_of_birth);
    if (hoy <= fechaFormulario) {
      error.date_of_birth = "El campo fecha de nacimiento debe ser antes de hoy!!";
    }
  }

  if (!form.species.trim()) {
    error.species = "El campo especie requerido!";
  }
  

  if (!form.species.trim()) {
    error.species = "El campo especie requerido!";
  }
  
  if (!form.sex.trim()) {
    error.sex = "El campo sexo es requerido!";
  }

  return error;
};
