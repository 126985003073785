import axiosAPI from "./axiosConfig"

const API_OWNERS="/accounts"

export const login_api=async (data)=>{    
    try{
        const res= await axiosAPI.post(`${API_OWNERS}/login/`, data)
        return res    
    }catch(err){
        return false
    }
    
    
}

export const me_api=async ()=>{   
    // const token=JSON.parse(localStorage.getItem('token'))
    // const config={headers:{ 
    //         'Authorization': `Bearer ${token.access}`
    //     }
    // }
    // const res = await axiosAPI.get(`${API_OWNERS}/me/`, config)
    const res = await axiosAPI.get(`${API_OWNERS}/me/`)
    return res.data
}


export const logout_api= async()=>{
    // const res = await axiosAPI.get(`${API_OWNERS}/logout/`)
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    axiosAPI.defaults.headers.common['Authorization'] = ''
}
