import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LayoutPublic } from "../../../components/layout/LayoutPublic";
import PaginationPet from "../../../components/pagination/PaginationPet";
import { loadVeterinaryThunks } from "../../../store/veterinaryThunks";
import TableVeterinarian from "../components/table_veterinarians";
import FormAdd from "../forms/FormAdd";
import FormFind from "../forms/FormFind";
import Loading from "../../../components/loading/Loading";

const TITLE = "Veterinarios";

const VeterinarianScreen = () => {
  const [modalCreate, setModalCreate] = useState(false);

  const { veterinarians,total_pages,current_page, total_items} = useSelector(
    (state) => state.veterinarian
  );
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(loadVeterinaryThunks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activarPage = (prt_number_page) => {
    dispatch(loadVeterinaryThunks(prt_number_page,10 ));

  };

  return (
    <LayoutPublic fluid>
      <Container>
        <h2>{TITLE}</h2>

        <Button
          variant="danger"
          onClick={() => setModalCreate(true)}
          className="mb-3"
        >
          Agregar {TITLE}
          <i className="fas fa-plus-circle mx-2"></i>
        </Button>
        <Row>
          <Col>
            <FormFind />
          </Col>
        </Row>
        <div>
          {veterinarians && (
              <TableVeterinarian
                veterinarians={veterinarians}
                getVeterinarians={() => {}}
              />
          )}
          { veterinarians && veterinarians.length>0 &&
            <PaginationPet
              activarPage={activarPage}
                total_items={total_items}
                current_page={current_page}
                total_pages={total_pages}
          />
          }
          {
            veterinarians && veterinarians.length===0 && <h4>No se encontraron resultados!</h4>
          }

        </div>
        {!veterinarians && (
            <Row className="mt-5">
              <Col>
                <Loading/>
              </Col>
            </Row>
          )}
      </Container>

      <Modal
        show={modalCreate}
        onHide={() => {
          setModalCreate(false);
        }}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> {`Crear ${TITLE}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormAdd
            closeModal={() => {
              setModalCreate(false);
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </LayoutPublic>
  );
};

export default VeterinarianScreen;
