import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoutes = ({is_allowed, redirectTo}) => {
    
  if (!is_allowed){
    return <Navigate to={redirectTo}/>
  }

  return (
    <Outlet/>
  )
}

export default ProtectedRoutes