export const TITLES_CUSTOMER={
    'name':'Nombre Completo',
    'ci':'Numero de Cédula',
    'phone':'Teléfonos de Contacto',
}

export const TITLES_PET={
    'name':'Nombre',
    'status':'Estado',
    'nro_chip':'N° Chip ID',
    'tattoo':'Tatuaje',
    'speci':'Especie',
    'raze':'Raza',
    'sex':'Sexo',
    'birthday':'Fecha de Nacimiento',
    'observations':'Observaciones',
}

export const TITLES_PET_SEX={
    'MALE':'Macho Entero',
    'MALE_INCOMPLETE':'Macho Castrado',
    'FEMALE':'Hembra Entera',
    'FEMALE_INCOMPLETE':'Hembra Castrada',
}

export const TITLES_PET_LOST={
    'LOST':'Perdido',
    'NOTLOST':'-------',
}

export const PET_RAZE_LIST = ["akita","Akita Inu", "Basset Hound", "Cocker Spaniel", "Dálmata", "Golden Retriever", "Labrador Retriever", "Pastor Alemán", "Poodle", "Rottweiler", "Yorkshire Terrier"];

export const PET_SPECIE_LIST = ["Perros", "Gatos", "Pájaros", "Conejos", "Hamsters", "Hurones", "Reptiles", "Peces", "Ratones", "Cobayas"];