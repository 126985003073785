import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteVeterinaryThunks } from "../../../store/veterinaryThunks";
import FormAdd from "../forms/FormAdd";
import FormAddCustomer from "../forms/FormAddCustomer";

const form_default = {
  pk: "",
  date_of_birth: "",
  name: "",
  number_chip: "",
  observations: "",
  race: "",
  sex: "",
  species: "",
  status: "",
  tattoo: "",
  reference: null,
  property_user: "",
};

const TITLE = "Veterinario";

const TableVeterinarian = ({veterinarians=[], getVeterinarians, form_edit=null}) => {

  const [modalEdit, setModalEdit]=useState(false)
  const [modalDelete, setModalDelete]=useState(false)
  const [modalCreateCustomer, setModalCreateCustomer] = useState(false);

  const [form, setForm] = useState(form_default);
// eslint-disable-next-line
  const {isEditing}=useSelector((state)=>state.veterinarian)
  const selectItemEdit = (item)=>{
    setForm(item)
    setModalEdit(true)
  }

  const selectItemDelete = (item)=>{
    setForm(item)
    setModalDelete(true)
  }

  const dispatch=useDispatch()
  const deleteVeterinarian=async ()=>{
    // const res = await veterinarian_delete_api(form.pk)
    dispatch(deleteVeterinaryThunks(form.pk))
    // if (res){
    //   setModalDelete(false)
    //   getVeterinarians()
    //   Swal.fire({
    //     position: 'top-end',
    //     icon: 'success',
    //     title: `${TITLE} eliminado!`,
    //     showConfirmButton: false,
    //     timer: 1500
    //   })
    //   return
    // }
      setModalDelete(false)
    //   Swal.fire({
    //     position: 'top-end',
    //     icon: 'warning',
    //     title: `Error: ${TITLE} no se ha eliminado!`,
    //     showConfirmButton: false,
    //     timer: 1500
    //   })
  }

  const selectAddCustomer=(item)=>{
    setForm(item)
    setModalCreateCustomer(true)
  }

  return (
    <>
    <Table  responsive="sm" striped bordered hover className="mt-2">
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Matricula</th>
            <th>Acciones</th>
          </tr>
        </thead>
      <tbody>
        {veterinarians.map((item, index) => {
          return (
            <tr key={index}>
              <td>{item.pk}</td>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td>{item.enrollment}</td>
              <td>
                <Button variant="danger" onClick={()=>selectAddCustomer(item)} className="m-2">
                  <i className="fas fa-user"></i>
                </Button>
                <Button
                    variant="danger"
                    onClick={() => selectItemEdit(item)}
                    className="me-2"
                  >
                    <i className="fas fa-edit"></i>
                  </Button>
                
                <Button variant="danger" onClick={() => selectItemDelete(item)}>
                  <i className="fas fa-trash-alt"></i>
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
    <Modal
        show={modalDelete}
        onHide={() => setModalDelete(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
          {`Eliminar ${TITLE}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, Esta seguro que desea eliminar a <strong>{form.name}</strong>!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{setModalDelete(false)}}>
            Cancelar
          </Button>
          <Button  variant="danger" onClick={deleteVeterinarian}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>     
        
      <Modal
        show={modalEdit}
        onHide={() => setModalEdit(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {`Editar ${TITLE}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormAdd form_edit={form} getVeterinarians={getVeterinarians} closeModal={()=>setModalEdit(false)}/>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalCreateCustomer}
        onHide={() => setModalCreateCustomer(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {`Crear Cliente para ${TITLE}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormAddCustomer veterinary={form} closeModal={()=>{setModalCreateCustomer(false)}} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TableVeterinarian;
