import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import "./NavbarPet.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOutAuthentication } from "../../store/thunks";
import Menu from "../menus/Menu";




const NavbarPet = () => {
  const dispatch = useDispatch();
  const logout = async () => {
    dispatch(getOutAuthentication());
  };

  const [pathname, setPathname] = useState("/");

  const location = useLocation();

  const user = useSelector((state) => state.auth);
  const { role, status } = user;

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  return (
    <Navbar className="mb-3" expand="lg">
      <Menu role={role} path_name={pathname} status={status} user={user} logout={logout}/>
    </Navbar>
  );
};

export default NavbarPet;
