import { useState } from "react"

export const useForm = (initialForm, validateForm) =>{
    const [form, setForm] = useState(initialForm)
    const [errors, setErrors] = useState({})
    // const [loading, setLoading] = useState(false)

    const handleChangeForm=(e)=>{
        const {name, value}=e.target
        setForm({...form,[name]:value})
    }

    const handleBlur=(e)=>{
        handleChangeForm(e)
        setErrors(validateForm(form))
    }

    const resetForm=()=>{
        setForm(initialForm)
        notBlur()
    }

    const notBlur=()=>{
        setErrors({})
    }


    return {
        form,
        setForm,
        handleChangeForm,
        resetForm,
        handleBlur,        
        errors,
        setErrors,
        notBlur
    }
}