import axiosAPI from "./axiosConfig"

const API_SEARCHS="mascot/pets/find/all?search"

export const search_api = async(data)=>{   
    try{
        const res = await axiosAPI.get(`/${API_SEARCHS}=${data}`,)
        return res.data
    } catch(err){
        return []
    }   
}