import React from "react";
import { COLORS } from "../../helpers/colors";
import { Image } from "react-bootstrap";

const PetTab = ({ action, id, name, active, light_imagen, dark_imagen }) => {
  return (
    <div className="justify-content-center pe-3" onClick={()=>action(id)}>
      <div className="link d-flex align-items-center" style={{
          color: active ? COLORS.red : COLORS.light_gray,
        }}>
        {active? (
          <Image src={light_imagen} style={{ width: "70px" }} />
        ) : (
          <Image src={dark_imagen} style={{ width: "70px" }} />
        )}
        
        {name}
      </div>
    </div>
  );
};

export default PetTab;
