import React from 'react'
import Pagination from 'react-bootstrap/Pagination';

const PaginationPet = ( {total_pages,current_page, activarPage, total_items}) => {

    const pageNumbers = Array.from({ length: total_pages }, (_, i) => i + 1);
    
    return (
      <>
      <div className='d-flex flex-row-reverse justify-content-between'>
      <h5>Total items: {total_items}</h5>
      <Pagination>
        {
          current_page>3 &&(<Pagination.Ellipsis  onClick={()=>{activarPage(current_page-2)}}/>)
        }
        {// eslint-disable-next-line
          pageNumbers.map((item)=>{
            if (item>current_page-3 && item<current_page ){
              return (<Pagination.Item onClick={()=>{activarPage(item)}} key={item} >{item}</Pagination.Item>)
            }
            if (item===current_page){
              return(<Pagination.Item onClick={()=>{activarPage(item)}} key={item} active>{item}</Pagination.Item>)
            }
            if (item<=current_page+3 && item>current_page ){
              return (<Pagination.Item onClick={()=>{activarPage(item)}} key={item} >{item}</Pagination.Item>)
            }
            return null
          }) 
        }
        {
          total_pages>current_page+3 &&(<Pagination.Ellipsis  onClick={()=>{activarPage(current_page+2)}} />)
        }
        
        </Pagination>
        </div>
        </>
    );
}

export default PaginationPet