import { login_api, logout_api, me_api} from "../api/users_api";
import { checkingCredentials, logout, signin  } from "./slices/authSlice"
import { setMessage } from "./slices/messageSlice";

export const startAuthentication=(email, password)=>{
    return async(dispatch)=>{
        dispatch(checkingCredentials())
        
        const res = await login_api({email, password});

        if(!res){
            dispatch(logout())
            dispatch(setMessage({message:"Usuario no existe",typeMessage:'error'}))
            return
        }
        localStorage.setItem("token", JSON.stringify(res.data));    
        const user= await me_api();
        localStorage.setItem("user", JSON.stringify({...user, initDate:new Date().getTime()}));    
        dispatch(signin({email:user.email, username: user.username, role: user.role, pk:user.pk}))
        dispatch(setMessage({message:`Bienvenido, ${user.username}`,typeMessage:'success'}))
    }
}

export const getOutAuthentication=()=>{
    return async(dispatch)=>{
        dispatch(checkingCredentials())
        dispatch(logout())
        try {
            await logout_api();
            dispatch(setMessage({message:`Session Terminada`,typeMessage:'success'}))    
        } catch (error) {
            dispatch(setMessage({message:`Error al logout`,typeMessage:'error'}))    
        }
        
    }
}