import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "../../../hooks/useForm";
import {  validations_form_veterinarians } from "../helpers/validations";
import { useDispatch, useSelector } from "react-redux";
import { createVeterinaryThunks, editVeterinaryThunks } from "../../../store/veterinaryThunks";

const form_default={
  pk:null,
  name:'',
  username:'',
  email:'',
  password:'',
  enrollment:'',
  department:'',
  role:'VETERINARY'
}

const FormAdd = ({ closeModal, form_edit=null }) => {
  const {
    form,
    handleChangeForm,
    handleBlur,
    errors,
    setErrors,
    resetForm,
  } = useForm(form_edit? form_edit:form_default, validations_form_veterinarians);

  const [validated, setValidated] = useState(false);
  
  const dispatch=useDispatch()
  // eslint-disable-next-line
  const {isEditing}=useSelector((state)=>state.veterinarian)

  const actualizarVeterinario=async ()=>{
    dispatch(editVeterinaryThunks(form)) 
    resetForm();
    closeModal();
    setValidated(false);    
  } 
  
  const crearVeterinario=async()=>{
    dispatch(createVeterinaryThunks(form))
    resetForm();
    closeModal();    
    setValidated(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validations_form_veterinarians(form);

    if (Object.keys(formErrors).length === 0) {
      setErrors(formErrors);
      if (form.pk) {
        actualizarVeterinario()        
        return;
      }
      crearVeterinario() 
     
    } else {
      setErrors(formErrors);
    }
  };

  return (
  <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Nombre</Form.Label>
        <Form.Control
          name="name"
          type="text"
          value={form.name}
          onChange={handleChangeForm}
          required
          onBlur={handleBlur}
        />
        {errors.name && <p style={styles_error}>{errors.name}</p>}
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
        <Form.Label>Correo</Form.Label>
        <Form.Control
          name="email"
          type="email"
          value={form.email}
          onChange={handleChangeForm}
          required
          onBlur={handleBlur}
        />
        {errors.email && <p style={styles_error}>{errors.email}</p>}
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
        <Form.Label>Username</Form.Label>
        <Form.Control
          name="username"
          type="text"
          value={form.username}
          onChange={handleChangeForm}
          required
          onBlur={handleBlur}
        />
        {errors.username && <p style={styles_error}>{errors.username}</p>}
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
        <Form.Label>Password</Form.Label>
        <Form.Control
          name="password"
          type="password"
          value={form.password}
          onChange={handleChangeForm}
          required
          onBlur={handleBlur}
        />
        {errors.password && <p style={styles_error}>{errors.password}</p>}
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
        <Form.Label>Matricula profesional</Form.Label>
        <Form.Control
          name="enrollment"
          type="number"
          value={form.enrollment}
          onChange={handleChangeForm}
          required
          onBlur={handleBlur}
        />
        {errors.enrollment && <p style={styles_error}>{errors.enrollment}</p>}
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
        <Form.Label>Departamento</Form.Label>
        <Form.Select
          name="department"
          type="text"
          value={form.department}
          onChange={handleChangeForm}
          required
          onBlur={handleBlur}
        >
          <option value="not">------</option>
          <option value="Beni">Beni</option>
          <option value="Santa_Cruz">SantaCruz</option>
          <option value="Cochabamba">Cochabamba</option>
          <option value="Pando">Pando</option>
          <option value="Oruro">Oruro</option>
          <option value="Tarija">Tarija</option>
          <option value="La_Paz">La Paz</option>
          <option value="Potosi">Potosi</option>
          <option value="Chuquisaca">Chuquisaca</option>
        </Form.Select>
        {errors.department && <p style={styles_error}>{errors.department}</p>}
      </Form.Group>
      <Form.Group className="justify-content-end" style={{ display: "flex" }}>
        <Button variant="danger" type="submit" >
          Guardar
        </Button>
        <Button variant="secondary" onClick={closeModal} className="ms-2">
          Cancelar
        </Button>
      </Form.Group>
    </Form>
  );
};

let styles_error = {
  fontWeight: "bold",
  color: "#dc3545",
};

export default FormAdd;
