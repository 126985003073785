import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { logout, signin } from "../store/slices/authSlice"


export  const useCheckAuth = () => {

    const user = useSelector((state)=>state.auth)
    const {status, role}=user
    const dispatch=useDispatch()

    useEffect(() => {
        const user_local=JSON.parse(localStorage.getItem('user'))

        if(!user_local){
            dispatch(logout())
            return
        }
        const previusDate=user_local.initDate
        const currentDate=new Date().getTime()
        const timeDifference = currentDate - previusDate;
        const hoursDifference = timeDifference / (1000 * 60 * 60); 
        if (hoursDifference >= 20) {
            dispatch(logout())
        }

        dispatch(signin({'username':user_local.username,'email':user_local.email,'role':user_local.role,}))
        // eslint-disable-next-line
    }, [])
    

  return {status, role}
}