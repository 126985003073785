import React from 'react'

import mascotas from "../../images/iconos/mascotas.svg";
import mascotas_g from "../../images/iconos/mascotas_g.svg";

// import historial from "../../images/iconos/lista.svg";
// import historial_g from "../../images/iconos/lista_g.svg";
import vacunas from "../../images/iconos/vacunas.svg";
import vacunas_g from "../../images/iconos/vacunas_g.svg";
// import control from "../../images/iconos/control.svg";
// import control_g from "../../images/iconos/control_g.svg";


import PetTab from './petTab';
import { Row } from 'react-bootstrap';

const PetMenu = ({ titles_tabs, handleTab, activeTab}) => {
  
  // const [activeTab, setActiveTab] = useState(1)
  
  return (
          <Row>
              <div className='d-flex justify-content-center align-items-center'>
                <PetTab id={titles_tabs[0].id} name={titles_tabs[0].name} action={handleTab} active={titles_tabs[0].id===activeTab } dark_imagen={mascotas_g} light_imagen={mascotas}/>
                <PetTab id={titles_tabs[1].id} name={titles_tabs[1].name} action={handleTab} active={titles_tabs[1].id===activeTab } dark_imagen={vacunas_g} light_imagen={vacunas}/>
                {/* <PetTab id={titles_tabs[2].id} name={titles_tabs[2].name} action={handleTab} active={titles_tabs[2].id===activeTab } dark_imagen={control_g} light_imagen={control}/>
                <PetTab id={titles_tabs[3].id} name={titles_tabs[3].name} action={handleTab} active={titles_tabs[3].id===activeTab } dark_imagen={historial_g} light_imagen={historial}/> */}
              </div>
          </Row>

  )
}

export default PetMenu