import React from "react";
import { COLORS } from "../../helpers/colors";
import "./TitleBar.css";
const TitleBar = ({ title }) => {
  return (
    <h3 className="title" style={{ fontSize: "0.8rem", backgroundColor:COLORS.red, color:COLORS.white }}>
      {title}
    </h3>
  );
};

export default TitleBar;
