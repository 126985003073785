import { veterinarians_api, veterinarian_delete_api, veterinarian_find_api, veterinarian_post_api, veterinarian_put_api } from "../apps/veterinanians/api/veterinarians_api";
import { setEditing,loadVeterinarians, deleteVeterinarian, updateVeterinarian, setVeterinarian } from "./slices/veterinarianSlice";
import { enviarEmail } from '../api/email_api'
import { setMessage } from "./slices/messageSlice";


export const findVeterinaryThunks =  (name) => {
  return async (dispatch)=>{
      const res = await veterinarian_find_api(name);
      if (res.error){
        dispatch(loadVeterinarians({veterinarians:[], total_items:0, current_page:0, total_pages:0}))
        return
      }
      dispatch(loadVeterinarians({veterinarians:res.results.filter((item)=>item.username!=='admin'), total_items:res.count, current_page:res.current_page, total_pages:res.total_pages}))
      
  }    
};

  export const loadVeterinaryThunks =  (page = 1, page_size = 10) => {
    return async (dispatch)=>{
        const res = await veterinarians_api(page,page_size);

        dispatch(loadVeterinarians({veterinarians:res.results, total_items:res.count, current_page:res.current_page, total_pages:res.total_pages}))
    }    
  };
  
  export const createVeterinaryThunks =  (form) => {
    return async (dispatch)=>{
      dispatch(setEditing())
      try{  
        const res = await veterinarian_post_api(form);        
        if(!res){
          dispatch(setMessage({message:"Puede que la matricula del veterinario existe o el nombre del usuario ya existe",typeMessage:'error'}))
          return  
        }
        const veterinary=res.data        
        dispatch(setVeterinarian({...veterinary}))
        dispatch(setMessage({message:"Veterinario Creado!",typeMessage:'success'}))
        }catch(e){
          dispatch(setMessage({message:"No se pudo crear al veterinario",typeMessage:'error'})) 
        }
      dispatch(loadVeterinaryThunks())
    }    
  };

  export const editVeterinaryThunks =  (veterinary) => {
    return async (dispatch)=>{
        try{
          const res = await veterinarian_put_api({...veterinary});    
          enviarEmail(veterinary, true)
          dispatch(updateVeterinarian({...res.data}))
          dispatch(setMessage({message:"Veterinario Actualizado!",typeMessage:'success'}))
        }catch(e){
          dispatch(setMessage({message:"No se pudo actualizar al veterinario",typeMessage:'error'})) 
        }        
    }    
  };

  export const deleteVeterinaryThunks = (pk) => {
    return async (dispatch)=>{
        dispatch(setEditing())
        const res=await veterinarian_delete_api(pk)
        if (res){
          dispatch(deleteVeterinarian(pk))
          dispatch(setMessage({message:"Veterinario Eliminado!",typeMessage:'success'}))
        }else{
          dispatch(setMessage({message:"No se pudo eliminar al veterinario!!",typeMessage:'error'}))
        }
    }    
  };