import { loadPets, loadVeterinarians } from "../slices/listSlice";


import { setLoading } from "../slices/vaccionationSlice";
import { pets_list, profiles_list } from "../../api/globals_list_api";

export const loadPetsThunks =  () => {
    return async (dispatch)=>{
        dispatch(setLoading(true))
        const res = await pets_list();
        dispatch(loadPets({pets:res.data}))
    }    
};

export const  loadVeterinariansThunks=  () => {
  return async (dispatch)=>{
      const res = await profiles_list();
      dispatch(loadVeterinarians({veterinarians:res.data}))
  }    
};




