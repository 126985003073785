import React from 'react'
import { Button } from 'react-bootstrap'

const ReadButton = ({action=()=>{}, title=""}) => {
  return (    
    <Button variant="danger" onClick={action} className="m-2">
      <i className='fas fa-file-lines'></i>{title}
    </Button>              
  )
}

export default ReadButton