import axiosAPI from "../../../api/axiosConfig"

const API_VETERINARIANS="accounts/profile"

export const veterinarians_api = async( page=1, page_size=10)=>{ 
    const res = await axiosAPI.get(`/${API_VETERINARIANS}?page=${page}&page_size=${page_size}`)    
    return res.data
}

export const veterinarian_post_api = async(data)=>{ 
    try{
        data['role']='VETERINARIAN'
        const res = await axiosAPI.post(`/${API_VETERINARIANS}/create/`,data)
        return res
    }catch(e){
        return false
    }
}

export const veterinarian_delete_api = async(id)=>{  
    try{
        const res=await axiosAPI.delete(`/${API_VETERINARIANS}/${id}/`)
        return res
    }catch(e){
        return false
    }
}


export const veterinarian_find_api = async(data)=>{     
    try{
        const res = await axiosAPI.get(`/${API_VETERINARIANS}/find?search=${data}`)
        
        return {error:false, ...res.data}
    }catch(err){
        return {error:true}
    }    
}

export const veterinarian_put_api = async(data)=>{   
    
    const res=await axiosAPI.patch(`/${API_VETERINARIANS}/${data.pk}/update/`,data)
    return res
    
}