import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { LayoutPublic } from "../../../components/layout/LayoutPublic";
import PaginationPet from "../../../components/pagination/PaginationPet";
import TableCustomers from "../components/table_customers";
import FormAdd from "../forms/FormAdd";
import FormFind from "../forms/FormFind";
import { useDispatch, useSelector } from "react-redux";
import { loadCustomersThunk} from "../../../store/customerThunks";

const TITLE = "Clientes";

const CustomersScreen = () => {  
  const dispatch=useDispatch()
  
  const {customers, isLoading, total_items, current_page, total_pages}=useSelector((state)=>state.customer)
  
  const { role } = useSelector((state) => state.auth);
  
  useEffect(() => {
    dispatch(loadCustomersThunk())
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

  const [modalCreate, setModalCreate] = useState(false);

  const activarPage = (prt_number_page) => {
    dispatch(loadCustomersThunk(prt_number_page,10 ));
  };

  return (
    <LayoutPublic fluid>
      <Container>
      <h2>{TITLE}</h2>
       {role!=='ADMIN' && <Button
          variant="danger"
          className="mb-3"
          onClick={() => setModalCreate(true)}
        > Agregar {TITLE}
        <i className="fas fa-plus-circle mx-2"></i>
      </Button>}
      <Row>
          <Col>
            <FormFind/>
          </Col>
        </Row>
      <div>
          {customers && (
            <>
              <TableCustomers customers={customers}/>
              <PaginationPet
                activarPage={activarPage}
                total_items={total_items}
                current_page={current_page}
                total_pages={total_pages}
              />
            </>
          )}
           {isLoading && <h4>Cargando...</h4>  }
        </div>        
      </Container>

      <Modal
        show={modalCreate}
        onHide={() => setModalCreate(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Crear {TITLE}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormAdd closeModal={()=>setModalCreate(false)}/>
        </Modal.Body>
      </Modal>     

    </LayoutPublic>
  );
};

export default CustomersScreen;
