import axios from "axios";

const axiosAPI = axios.create({
  //baseURL: 'http://localhost:8080/api'
  // baseURL:'https://upipets.com.bo'
  // baseURL:'https://upipets.com.bo/api'
   baseURL:'https://ubipets.com.bo/api'
});

axiosAPI.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("token"));
    
    if (token) {
      config.headers.Authorization = `Bearer ${token.access}`;
    }
    
    return config;
  },
  (error) => {
    
    return Promise.reject(error);
  }
);

export default axiosAPI