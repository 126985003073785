import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  messageSuccess:null,
  messageError:null,
}

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessage:(state, {payload})=>{
      if (payload.typeMessage==='success'){
        state.messageSuccess=payload.message;
      }
      if (payload.typeMessage==='error'){
        state.messageError=payload.message;
      }      
    },
    resetMessages:(state)=>{
      state.messageError=null;
      state.messageSuccess=null;
    },
  },
})

// Action creators are generated for each case reducer function
export const { resetMessages, setMessage } = messageSlice.actions