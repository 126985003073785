import axiosAPI from "../../../api/axiosConfig"


const API_CUSTOMERS="mascot/propertyusers"

export const customers_api = async( page=1, page_size=10)=>{ 
    const res = await axiosAPI.get(`/${API_CUSTOMERS}?page=${page}&page_size=${page_size}`)    
    return res.data
}


export const customer_post_api = async(data)=>{
    try{
        const res=await axiosAPI.post(`/${API_CUSTOMERS}/create/`,data)
        return res
    }catch(err){
        return false
    }       
}

export const customer_delete_api = async(pk)=>{    
    const res = await axiosAPI.delete(`/${API_CUSTOMERS}/${pk}/delete/`)
    return res
}

export const customer_put_api = async(data)=>{  
    const res = await axiosAPI.patch(`/${API_CUSTOMERS}/${data.pk}/update/`,data)
    return res
}

export const customer_find_api = async(data)=>{     
    try{
        const res = await axiosAPI.get(`/${API_CUSTOMERS}/find?search=${data}`)
        return res.data
    }catch(err){
        return []
    }    
}

const API_PETS="mascot/pets"

export const pet_post_api = async(data)=>{    
    try{
        const res = await axiosAPI.post(`/${API_PETS}/create/`,data,{headers: { "Content-Type": "multipart/form-data" }})
        return res.data
    }catch(err){
        return false
    }   
}